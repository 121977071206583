import { useMemo } from 'react';

import { EStatus, ETypeVesting } from 'shared/interfaces';
import { StatusTimeLocales, StatusVestingTimeLocales } from 'shared/utils';

import styles from './styles';
import Timestamp from './Timestamp';

interface ITimer {
  time: number;
  status?: EStatus;
  vestingType?: ETypeVesting | null,
  hideBackground?: boolean,
  isDistributionIn?: boolean,
}

const getVestingTitle = (vestingType?: ETypeVesting | null) => {
  switch (vestingType) {
    case ETypeVesting.OneTime: return StatusVestingTimeLocales[vestingType];
    case ETypeVesting.Stepwise: return StatusVestingTimeLocales[vestingType];
    default: return null;
  }
};

const getStatusTitle = (status: EStatus) => {
  switch (status) {
    case EStatus.Soon: return StatusTimeLocales[status];
    case EStatus.Open: return StatusTimeLocales[status];
    default: return null;
  }
};

export default function Timer(
  {
    time,
    status,
    vestingType,
    hideBackground,
    isDistributionIn,
  }: ITimer,
) {
  const vestingOrStatusTitle = useMemo(() => (status && status !== EStatus.Closed
    ? getStatusTitle(status)
    : getVestingTitle(vestingType)), [status, vestingType]);
  const cliff = isDistributionIn ? 'Distribution in' : null;
  const title = vestingOrStatusTitle || cliff;
  if (!title) return null;

  return (
    <styles.TimerBlock isVesting={hideBackground}>
      <span>
        {title}
      </span>
      <Timestamp
        time={time}
      />
    </styles.TimerBlock>
  );
}
