import { IVestingArray, ETypeVesting } from 'shared/interfaces';

import { detectActiveVesting } from './vesting';

const checkVestingShouldUpdate = (
  vestingArray: IVestingArray[],
  vestingType: ETypeVesting | null,
  vestingEnded: boolean,
  lastCheck: boolean,
) => {
  if (vestingEnded) return !lastCheck;
  const newActiveVesting = detectActiveVesting(vestingArray, vestingType);
  if (!newActiveVesting || newActiveVesting.date > Date.now()) return false;
  return true;
};

export default checkVestingShouldUpdate;
