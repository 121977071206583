import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useData } from 'providers/DataProvider';
import { getToken } from 'providers/helpers';
import { ISale } from 'providers/interfaces';
import { APP_ROUTES } from 'routes/constant';
import { calcTotalClaimAvailable } from 'shared/calculation';
import AdminPanel from 'shared/components/AdminPanel';
import AmountProgress from 'shared/components/AmountProgress';
import SaleDate from 'shared/components/SaleDate';
import SaleLayoutInfo from 'shared/components/SaleLayoutInfo';
import SocialNetwork from 'shared/components/SocialNetwork';
import ValueSale from 'shared/components/ValueSale';
import VestingSchedule from 'shared/components/VestingSchedule';
import VestingSection from 'shared/components/VestingSection';
import { ZERO, ZERO_STR } from 'shared/constants';
import useStatus from 'shared/hooks/useStatus';
import { EStatus } from 'shared/interfaces';
import { colors } from 'shared/theme';

import styles from './styles';

export default function SalePage() {
  const { sales, loading, tokens } = useData();
  const { id } = useParams();
  const navigate = useNavigate();
  const [sale, setSale] = useState<ISale | null>(null);
  const { status } = useStatus(
    sale?.startDate || ZERO,
    sale?.endDate || ZERO,
    sale?.totalAmount || ZERO_STR,
    sale?.collectedAmount || ZERO_STR,
    sale?.saleType,
    sale?.status,
  );

  useEffect(() => {
    if (id && sales[id] && (!sales || sale !== sales[id])) {
      setSale(sales[id]);
    }
  }, [id, loading]);

  const token = getToken(sale?.depositTokeId || '', tokens);

  if (loading || !sale || !token || !token.metadata) return null;

  const showVesting = sale.claimAvailable
    && status === EStatus.Closed;
  const showVestingSchedule = status === EStatus.Soon
    || status === EStatus.Open
    || sale.cliff?.active;
  return (
    <>
      <styles.Container>
        <styles.Wrapper>
          <styles.CloseBtn onClick={() => navigate(APP_ROUTES.HOME)} />
          <SaleLayoutInfo
            currency={token.metadata.symbol}
            name={sale.metadata.name}
            tokenIcon={token.metadata.icon}
            saleType={sale.saleType}
            status={status}
          />
          {
            sale.vesting && showVesting && (
              <VestingSection
                rewardTicker={sale.metadata.rewardTicker}
                vesting={sale.vesting}
                vestingType={sale.vestingType}
                cliff={sale.cliff}
                totalClaimAvailable={calcTotalClaimAvailable(sale.collectedAmount, sale.price, token.metadata.decimals)}
              />
            )
          }
          <styles.InfoWrapper>
            <ValueSale
              depositSymbol={token.metadata.symbol}
              depositTokenDecimals={token.metadata.decimals}
              rewardTicker={sale.metadata.rewardTicker}
              minBuy={sale.minBuy}
              maxBuy={sale.maxBuy}
              claimBegun={sale.claimBegun}
              price={sale.price}
              totalAmount={sale.totalAmount}
              numAccountSales={sale.numAccountSales}
            />
            <AmountProgress
              collectedAmount={sale.collectedAmount}
              currency={token.metadata.symbol}
              depositTokenDecimals={token.metadata.decimals}
              totalAmount={sale.totalAmount}
            />
            <SaleDate
              endDate={sale.endDate}
              startDate={sale.startDate}
            />
          </styles.InfoWrapper>
          {
            sale.vesting
            && showVestingSchedule
            && (
              <VestingSchedule
                vesting={sale.vesting}
                vestingType={sale.vestingType}
              />
            )
          }
          <styles.Description>
            {sale.metadata.description}
          </styles.Description>
          <styles.SocialWrapper>
            <styles.SocialBox href={sale.metadata.projectUrl}>
              <p>
                {sale.metadata.name}
              </p>
              <styles.ExternalLink />
            </styles.SocialBox>
            <SocialNetwork
              socials={sale.metadata.socials}
              color={colors.black}
              isCardPage
            />
          </styles.SocialWrapper>
        </styles.Wrapper>
      </styles.Container>
      <AdminPanel sale={sale} depositTokenMetadata={token.metadata} />
    </>
  );
}
