import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.lightOrange};
  color: ${({ theme }) => theme.black};
  border-radius: 1rem;
  margin-top: 4rem;
  padding: 1rem;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 2rem;
`;

const Vesting = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  & > div:last-child {
    display: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
  `}
`;

const Label = styled.p`
  text-align: right;
  font-weight: 500;
  font-size: .75rem;
  line-height: .938rem;
  color: ${({ theme }) => theme.primaryGrey};
  margin-bottom: 1.25rem;
  min-width: 25rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
  `}
`;

const Row = styled.div<{ isOneTimeVesting?: boolean }>`
  display: flex;
  justify-content: space-between;
  & > span {
    font-style: normal;
    font-size: ${({ isOneTimeVesting }) => (isOneTimeVesting ? '.75rem' : '.875rem')};
    line-height: 1.063rem;
  }
  & > span:first-child {
    color: ${({ isOneTimeVesting, theme }) => (isOneTimeVesting ? theme.primaryGrey : theme.black)};
    font-weight: ${({ isOneTimeVesting }) => (isOneTimeVesting ? '500' : '600')};
  }
  & > span:last-child {
    font-weight: 400;
  }
`;

const Division = styled.div`
  margin: .75rem 0;
  height: .063rem;
  border-radius: .25rem;
  background-color: ${({ theme }) => theme.orangeDivision};
`;

export default {
  Container,
  Title,
  Vesting,
  Row,
  Label,
  Division,
};
