import { ISaleAccounts, ITokenMetadata } from 'services/interfaces';

import { formatTokenAmount } from './formatAmount';

const generateList = (array: ISaleAccounts[], metadata: ITokenMetadata) => array
  .reduce(
    (acc, el) => `${acc + el[0]}, ${formatTokenAmount(el[1].amount, metadata.decimals)}\n`,
    '',
  );

export default generateList;
