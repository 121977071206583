import { Button } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';

import { createFormattedDate } from 'services/helpers';
import { calcDistributeAmount } from 'shared/calculation';
import { IPeriod } from 'shared/interfaces';
import { emptyPeriods } from 'shared/utils';

import styles from './styles';

interface IPeriodSection {
  periods: IPeriod[],
  setPeriods: (periods: IPeriod[]) => void,
  totalAmount?: string,
}

export default function PeriodSection({ periods, setPeriods, totalAmount }: IPeriodSection) {
  const addPeriod = () => {
    setPeriods([...periods, emptyPeriods]);
  };

  const removePeriod = (id: number) => {
    const shallowCopy = [...periods];
    shallowCopy.splice(id, 1);
    setPeriods(shallowCopy);
  };

  return (
    <>
      {periods.map((period, index) => (
        <styles.PeriodContainer key={`${index + 1}`}>
          <styles.PeriodTitle>
            <p>
              Period #
              {index + 1}
            </p>
            <p>
              {calcDistributeAmount(totalAmount, period.percent)}
              {' '}
              NEAR
            </p>
          </styles.PeriodTitle>
          <TextField
            label="Percent of distribution"
            value={period.percent}
            type="number"
            autoComplete="off"
            onWheel={(e: any) => e.target!.blur()}
            onChange={(e) => {
              setPeriods(periods.map((obj, prevStateIndex) => {
                if (index === prevStateIndex) {
                  return { ...obj, percent: e.target.value };
                }
                return obj;
              }));
            }}
          />
          {index === 0 && (
            <TextField
              label="Steps"
              value={period.steps}
              type="number"
              autoComplete="off"
              onWheel={(e: any) => e.target!.blur()}
              onChange={(e) => {
                setPeriods(periods.map((obj, prevStateIndex) => (
                  index === prevStateIndex
                    ? { ...obj, steps: e.target.value }
                    : obj
                )));
              }}
            />
          )}
          <styles.Row>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {index === 0
                ? (
                  <DateTimePicker
                    renderInput={(params: TextFieldProps) => <TextField {...params} error={false} />}
                    label="Start Period (UTC)"
                    value={period.startPeriod}
                    onChange={(newValue: Dayjs | null) => {
                      setPeriods(periods.map((obj, prevStateIndex) => (
                        index === prevStateIndex
                          ? { ...obj, startPeriod: createFormattedDate(newValue) }
                          : obj
                      )));
                    }}
                    ampm={false}
                  />
                )
                : (
                  <TextField
                    label="Steps"
                    value={period.steps}
                    type="number"
                    autoComplete="off"
                    onWheel={(e: any) => e.target!.blur()}
                    onChange={(e) => {
                      setPeriods(periods.map((obj, prevStateIndex) => (
                        index === prevStateIndex
                          ? { ...obj, steps: e.target.value }
                          : obj
                      )));
                    }}
                  />
                )}
              <DateTimePicker
                renderInput={(params) => <TextField {...params} error={false} />}
                label="End Period (UTC)"
                value={period.endPeriod}
                onChange={(newValue: Dayjs | null) => {
                  setPeriods(periods.map((obj, prevStateIndex) => (
                    index === prevStateIndex
                      ? { ...obj, endPeriod: createFormattedDate(newValue) }
                      : obj
                  )));
                }}
                ampm={false}
              />
            </LocalizationProvider>
          </styles.Row>
          {index !== 0 && (
            <Button
              variant="outlined"
              color="warning"
              onClick={() => removePeriod(index)}
            >
              Remove period
            </Button>
          )}
        </styles.PeriodContainer>
      ))}
      <styles.AddPeriod onClick={addPeriod}>
        <styles.Plus />
        Add Period
      </styles.AddPeriod>
    </>
  );
}
