import { EStatus, ETypeVesting } from 'shared/interfaces';

export const StatusLocales = {
  [EStatus.Open]: 'Open',
  [EStatus.Soon]: 'Soon',
  [EStatus.Closed]: 'Closed',
};

export const StatusTimeLocales = {
  [EStatus.Open]: 'Left to Finish',
  [EStatus.Soon]: 'Left to Start',
};

export const StatusVestingTimeLocales = {
  [ETypeVesting.OneTime]: 'Left to unlock',
  [ETypeVesting.Stepwise]: 'Left to next unlock',
};
