import {
  Action, ISaleAccounts, ISaleContract, SaleOutput, VestingOutput,
} from 'services/interfaces';
import RPCProviderService, { IRPCProviderService } from 'services/RPCProviderService';
import { IFormattedValues } from 'shared/interfaces';

import { SaleViewMethods, SaleChangeMethods } from './contractMethods';

export default class SaleContract implements ISaleContract {
  readonly contractId: string;

  private provider: IRPCProviderService;

  public constructor(contractId: string, provider: RPCProviderService) {
    this.contractId = contractId;
    this.provider = provider;
  }

  async getNumSales(): Promise<number | undefined> {
    return this.provider.viewFunction(SaleViewMethods.getNumSales, this.contractId);
  }

  async getSales(from: number, limit: number): Promise<SaleOutput[] | undefined> {
    return this.provider.viewFunction(SaleViewMethods.getSales, this.contractId, { from_index: from, limit });
  }

  async getSaleAccounts(saleId: number, from: number, limit: number): Promise<ISaleAccounts[]> {
    try {
      const accounts = await this.provider.viewFunction(
        SaleViewMethods.getSaleAccounts,
        this.contractId,
        { sale_id: saleId, from_index: from, limit },
      );
      if (!accounts) return [];
      return accounts;
    } catch (e) {
      console.warn(`Error: ${e} while get sale accounts`);
      return [];
    }
  }

  createSale(values: IFormattedValues): Action {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: SaleChangeMethods.createSale,
        args: {
          sale: {
            metadata: {
              name: values.projectName,
              symbol: values.depositTokenSymbol,
              description: values.description,
              smart_contract_url: values.projectSite,
              logo_url: '',
              output_ticker: values.projectSymbol,
              project_telegram: values.socialTg,
              project_medium: values.socialMedium,
              project_twitter: values.socialTwitter,
            },
            staking_contracts: values.stakingContracts,
            max_amount: values.maxAmount,
            min_near_deposit: values.minNearDeposit,
            deposit_token_id: values.depositTokenId,
            distribute_token_decimals: values.distributeTokenDecimals,
            distribute_token_id: values.distributeTokenId,
            min_buy: values.minBuy,
            max_buy: values.maxBuy,
            limit_per_transaction: values.limitPerTransaction,
            start_date: values.startAt,
            end_date: values.endAt,
            price: values.price,
            sale_type: values.roundType,
            claim_available: values.claimAvailable,
            refund_available: values.refundAvailable,
            vesting: values.vesting,
          },
        },
      }],
    };
  }

  removeSale(saleId: number): Action {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: SaleChangeMethods.removeSale,
        args: { sale_id: saleId },
      }],
    };
  }

  updateSaleDates(saleId: number, startDate: number, endDate: number): Action {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: SaleChangeMethods.updateSaleDates,
        args: {
          sale_id: saleId,
          start_date: startDate.toString(),
          end_date: endDate.toString(),
        },
      }],
    };
  }

  updateSaleDistributeTokenId(saleId: number, distributeTokenId: string): Action {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: SaleChangeMethods.updateSaleDistributeTokenId,
        args: {
          sale_id: saleId,
          distribute_token_id: distributeTokenId,
        },
      }],
    };
  }

  updateSalePrice(saleId: number, price: string, totalAmount: string): Action {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: SaleChangeMethods.updateSalePrice,
        args: {
          sale_id: saleId,
          price,
          max_amount: totalAmount,
        },
      }],
    };
  }

  updateSaleClaimAvailable(saleId: number, claimAvailable: boolean): Action {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: SaleChangeMethods.updateSaleClaimAvailable,
        args: {
          sale_id: saleId,
          claim_available: claimAvailable,
        },
      }],
    };
  }

  updateSaleRefundAvailable(saleId: number, refundAvailable: boolean): Action {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: SaleChangeMethods.updateSaleRefundAvailable,
        args: {
          sale_id: saleId,
          refund_available: refundAvailable,
        },
      }],
    };
  }

  changeVesting(saleId: number, inputVesting: VestingOutput[]): Action {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: SaleChangeMethods.changeVesting,
        args: {
          sale_id: saleId,
          input_vesting: inputVesting,
        },
      }],
    };
  }
}
