import dayjs from 'dayjs';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  & > p {
    display: flex;
    justify-content: center;
    flex: 1;
    font-weight: 400;
    font-size: .75rem;
    color: ${({ theme }) => theme.gray};
    margin: 0;
  }
`;

export default function Footer(): JSX.Element {
  const year = dayjs().year();
  return (
    <Container>
      <p>Bocachica launchpad</p>
      <p>
        Copyright
        {' '}
        {year}
      </p>
    </Container>
  );
}
