import { IConfig } from '../config';

export default {
  networkId: 'testnet',
  nodeUrl: 'https://rpc.testnet.near.org',
  walletUrl: 'https://wallet.testnet.near.org',
  helperUrl: 'https://helper.testnet.near.org',
  explorerUrl: 'https://explorer.testnet.near.org',
  contractId: 'bocadmin.testnet',
  wNearAddress: 'wrap.testnet',
  walletId: 'testnet',
  myNearWalletUrl: undefined,
  usn: 'usdn.testnet',
} as IConfig;
