import { Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useData } from 'providers/DataProvider';
import { ISale } from 'providers/interfaces';
import { APP_ROUTES } from 'routes/constant';
import Card from 'shared/components/Card';
import { toArray } from 'shared/utils';

import styles from './styles';

export default function HomePage() {
  const { sales, loading } = useData();
  const salesArray = toArray(sales).reverse();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredSales, setFilteredSales] = useState<ISale[]>([]);

  const onChange = useCallback((search: string) => {
    setSearchValue(search);
    const auctionsBySearch = search !== ''
      ? salesArray.filter((sale) => sale.metadata.name.toLowerCase().includes(search.toLowerCase()))
      : salesArray;
    setFilteredSales(auctionsBySearch);
  }, [salesArray]);

  useEffect(() => {
    if (salesArray.length !== filteredSales.length && searchValue === '') {
      setFilteredSales(salesArray);
    }
  }, [searchValue, filteredSales.length, salesArray]);
  if (loading) return <styles.Loading />;
  return (
    <styles.Container>
      <styles.Header>
        <styles.StyledInput>
          <styles.Search />
          <styles.Input
            value={searchValue}
            onChange={(value) => onChange(value.target.value)}
            placeholder="Search"
          />
          {searchValue && <styles.ClearSearch onClick={() => onChange('')} />}
        </styles.StyledInput>
        <Button
          variant="contained"
          color="warning"
          onClick={() => navigate(APP_ROUTES.CREATE_SALE)}
        >
          Create Sale
        </Button>
      </styles.Header>
      <styles.WrapperCards>
        {filteredSales.map((sale) => (
          <Card
            key={`${sale.metadata.name}: ${sale.id}`}
            sale={sale}
          />
        ))}
      </styles.WrapperCards>
    </styles.Container>
  );
}
