import { calcTotalVestingValue } from 'shared/calculation';
import { IValues } from 'shared/interfaces';
import { colors } from 'shared/theme';
import { formatValuesToSale } from 'shared/utils';

import AmountProgress from '../AmountProgress';
import SaleDate from '../SaleDate';
import SaleLayoutInfo from '../SaleLayoutInfo';
import SocialNetwork from '../SocialNetwork';
import ValueSale from '../ValueSale';
import VestingSchedule from '../VestingSchedule';
import styles from './styles';

interface ISaleCard {
  values: IValues,
}

export default function SaleCard({ values }: ISaleCard) {
  const { sale, metadata } = formatValuesToSale(values);
  return (
    <styles.Container>
      <styles.Wrapper>
        <SaleLayoutInfo
          currency={metadata.symbol}
          name={sale.metadata.name}
          tokenIcon={metadata.icon}
          saleType={sale.saleType}
          status={sale.status}
        />
        <styles.InfoWrapper>
          <ValueSale
            depositSymbol={metadata.symbol}
            rewardTicker={sale.metadata.rewardTicker}
            minBuy={sale.minBuy}
            maxBuy={sale.maxBuy}
            claimBegun={false}
            price={sale.price}
            totalAmount={sale.totalAmount}
            numAccountSales={sale.numAccountSales}
          />
          <AmountProgress
            collectedAmount={sale.collectedAmount}
            currency={metadata.symbol}
            totalAmount={sale.totalAmount}
          />
          <SaleDate
            endDate={sale.endDate}
            startDate={sale.startDate}
          />
        </styles.InfoWrapper>
        {
          sale.vesting.length && (
            <VestingSchedule
              vesting={sale.vesting}
              vestingType={sale.vestingType}
              totalClaimAvailable={calcTotalVestingValue(
                sale.totalAmount,
                sale.price,
              )}
            />
          )
        }
        {
          sale.metadata.description && (
            <styles.Description>
              {sale.metadata.description}
            </styles.Description>
          )
        }
        {
          sale.metadata.projectUrl && (
            <styles.SocialWrapper>
              <styles.SocialBox href={sale.metadata.projectUrl}>
                <p>
                  {sale.metadata.name}
                </p>
                <styles.ExternalLink />
              </styles.SocialBox>
              <SocialNetwork
                socials={sale.metadata.socials}
                color={colors.black}
                isCardPage
              />
            </styles.SocialWrapper>
          )
        }
      </styles.Wrapper>
    </styles.Container>
  );
}
