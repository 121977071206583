import { social } from 'shared/constants';

import { TextInput } from '../Input/FormikInput';
import styles from './styles';

export default function Network() {
  return (
    <styles.Wrapper>
      <styles.Title>Network</styles.Title>
      <styles.ChildWrapper>
        {social.map(({
          label, type, name, placeholder,
        }) => (
          <TextInput
            key={name}
            label={label}
            name={name}
            type={type}
            placeholder={placeholder}
          />
        ))}
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
