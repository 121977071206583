import { NativeSelect } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import React, { useEffect, useState } from 'react';

import { VestingOutput } from 'services/interfaces';
import {
  ETypeVesting,
  EPeriodsErrors,
  IPeriod,
  ErrorsPeriodsMap,
} from 'shared/interfaces';
import { EAdminValue, initialPeriod, retrieveVesting } from 'shared/utils';

import DistributeDateSection from './DistributeDateSection';
import PeriodSection from './PeriodSection';
import styles from './styles';

interface VestingOutputAdminPanel {
  setIsValidVesting: React.Dispatch<React.SetStateAction<boolean>>
  saleTotalAmount?: string,
  saleEndDate?: number,
  initialVestingType: ETypeVesting,
  currentVesting: VestingOutput[] | undefined,
  handleChange: (vesting: VestingOutput[]) => void
}

function VestingWrapper({
  children,
  error,
  handleChangeSelect,
}:
{
  children?: JSX.Element,
  error: EPeriodsErrors | null,
  handleChangeSelect: (e: { target: { value: string } }) => void,
}) {
  return (
    <styles.Container>
      <NativeSelect
        name={EAdminValue.vestingType}
        onChange={handleChangeSelect}
      >
        <option value={ETypeVesting.OneTime}>One Time</option>
        <option value={ETypeVesting.Stepwise}>Stepwise</option>
      </NativeSelect>
      {children}
      {error && (
      <FormHelperText error>{ErrorsPeriodsMap[error]}</FormHelperText>
      )}
    </styles.Container>
  );
}

export default function VestingAdminPanel({
  initialVestingType,
  setIsValidVesting,
  saleEndDate,
  saleTotalAmount,
  currentVesting,
  handleChange,
}: VestingOutputAdminPanel) {
  const [periods, setPeriods] = useState<IPeriod[]>([initialPeriod]);
  const [vestingType, setVestingType] = useState<ETypeVesting>(initialVestingType);
  const [periodError, setPeriodError] = useState<EPeriodsErrors | null>(null);

  const handleChangeSelect = (e: { target: { value: string } }) => {
    setVestingType((prevState) => {
      if (prevState !== e.target.value) {
        setPeriods([initialPeriod]);
      }
      return e.target.value as ETypeVesting;
    });
  };

  useEffect(() => {
    const retrievedVesting = retrieveVesting(periods, vestingType, saleEndDate);
    if (!retrievedVesting) return;
    setIsValidVesting(!retrievedVesting.error);
    setPeriodError(retrievedVesting.error);
    if (retrievedVesting.error) return;
    setIsValidVesting(true);
  }, [currentVesting, periods, saleEndDate, setIsValidVesting, vestingType]);

  const updatePeriodsInfo = (newPeriods: IPeriod[]) => {
    const retrievedVesting = retrieveVesting(newPeriods, vestingType, saleEndDate);
    if (retrievedVesting) {
      handleChange(retrievedVesting.vestingArray);
    } else {
      handleChange([]);
    }
    return setPeriods(newPeriods);
  };

  switch (vestingType) {
    case ETypeVesting.OneTime:
      return (
        <VestingWrapper
          error={periodError}
          handleChangeSelect={handleChangeSelect}
        >
          <DistributeDateSection
            setPeriods={updatePeriodsInfo}
          />
        </VestingWrapper>
      );
    case ETypeVesting.Stepwise:
      return (
        (
          <VestingWrapper
            error={periodError}
            handleChangeSelect={handleChangeSelect}
          >
            <PeriodSection
              periods={periods}
              setPeriods={updatePeriodsInfo}
              totalAmount={saleTotalAmount}
            />
          </VestingWrapper>
        )
      );
    default:
      return (
        <VestingWrapper
          error={periodError}
          handleChangeSelect={handleChangeSelect}
        />
      );
  }
}
