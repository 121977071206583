import { EFromValues, ERoundType, IValues } from 'shared/interfaces';
import { getUpperCase, isNullOrEmpty } from 'shared/utils';

import { Select, TextInput } from '../Input/FormikInput';
import styles from './styles';

interface IRoundInformation {
  values: IValues,
}

export default function RoundInformation({ values }: IRoundInformation) {
  const depositTokenId = getUpperCase(values.depositToken?.contractId || '');
  const tokenSymbol = values.depositToken?.metadata ? values.depositToken.metadata.symbol : depositTokenId;

  const projectSymbolToUpperCase = getUpperCase(values.projectSymbol);
  const projectSymbol = isNullOrEmpty(projectSymbolToUpperCase) ? 'HAPI' : projectSymbolToUpperCase;

  const depositTokenSymbol = isNullOrEmpty(tokenSymbol) ? 'NEAR' : tokenSymbol;
  const depositTokenPrice = isNullOrEmpty(values.price) ? '1' : values.price;

  const priceLabel = `Price (exp: 1 ${projectSymbol} for ${depositTokenPrice} ${depositTokenSymbol})`;

  return (
    <styles.Wrapper>
      <styles.Title>Round Information</styles.Title>
      <styles.ChildWrapper>
        <Select name={EFromValues.roundType}>
          <option value={ERoundType.Empty}>Select a round type</option>
          <option value={ERoundType.ByAmount}>Amount</option>
          <option value={ERoundType.BySubscription}>Subscription</option>
        </Select>
        <TextInput
          label="Max Amount"
          name={EFromValues.maxAmount}
          type="number"
          placeholder={`9000 ${tokenSymbol}`}
        />
        <TextInput
          label="Min allocation (per 1 wallet)"
          name={EFromValues.minAllocation}
          type="number"
          placeholder={`5 ${tokenSymbol}`}
        />
        <TextInput
          label="Max allocation (per 1 wallet)"
          name={EFromValues.maxAllocation}
          type="number"
          placeholder={`50 ${tokenSymbol}`}
        />
        <TextInput
          label="Limit Per Transaction (per 1 wallet)"
          name={EFromValues.limitPerTransaction}
          type="number"
          placeholder={`50 ${tokenSymbol}`}
        />
        <TextInput
          label={priceLabel}
          name={EFromValues.price}
          type="number"
          placeholder="0,15"
        />
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
