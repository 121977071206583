import BN from 'bn.js';
import dayjs, { Dayjs } from 'dayjs';
import { utils } from 'near-api-js';

export const getGas = (gas?: string) => (gas ? new BN(gas) : new BN('100000000000000'));
export const getAmount = (amount?: string) => (amount
  ? new BN(utils.format.parseNearAmount(amount) ?? 0)
  : new BN('0'));

export const createFormattedDate = (date: Date | string | null | Dayjs) => dayjs(date)
  .set('seconds', 0).format('YYYY-MM-DD HH:mm:ss');
