/* eslint-disable @typescript-eslint/naming-convention */
import { ISale } from 'providers/interfaces';
import { SaleOutput } from 'services/interfaces';
import { ESocial, ETypeVesting, IVesting } from 'shared/interfaces';

import { getStatus } from './calculationDate';
import { nanosecondsToMilliseconds } from './formatAmount';
import {
  formatVesting, getCliffDetails, getVestingArray, getVestingType,
} from './vesting';

const formatSale = (sale: SaleOutput): ISale => {
  const {
    name,
    symbol,
    description,
    smart_contract_url,
    logo_url,
    output_ticker,
    project_telegram,
    project_medium,
    project_twitter,
    reward_timestamp,
    reward_description,
  } = sale.metadata;

  const startDate = nanosecondsToMilliseconds(sale.start_date);
  const endDate = nanosecondsToMilliseconds(sale.end_date);
  const rewardDate = reward_timestamp
    ? nanosecondsToMilliseconds(reward_timestamp)
    : undefined;

  const status = getStatus(
    startDate,
    endDate,
    sale.max_amount,
    sale.collected_amount,
    sale.sale_type,
  );

  const formattedVesting = formatVesting(sale.vesting);
  const vestingType: ETypeVesting | null = getVestingType(formattedVesting);
  const vesting: IVesting[] | null = getVestingArray(formattedVesting, vestingType, endDate);
  const cliff = getCliffDetails(formattedVesting);

  return {
    id: sale.sale_id,
    depositTokeId: sale.deposit_token_id,
    claimAvailable: sale.claim_available,
    refundAvailable: sale.refund_available,
    distributeTokenId: sale.distribute_token_id,
    distributeTokenDecimals: sale.distribute_token_decimals,
    minBuy: sale.min_buy,
    maxBuy: sale.max_buy,
    totalAmount: sale.max_amount,
    startDate,
    endDate,
    rewardDate,
    price: sale.price,
    limitPerTransaction: sale.limit_per_transaction,
    collectedAmount: sale.collected_amount,
    numAccountSales: sale.num_account_sales,
    saleType: sale.sale_type,
    claimBegun: sale.claim_begun,
    metadata: {
      socials: [
        { value: project_telegram, type: ESocial.Telegram },
        { value: project_medium, type: ESocial.Medium },
        { value: project_twitter, type: ESocial.Twitter },
      ],
      name,
      depositSymbol: symbol,
      description,
      projectUrl: smart_contract_url,
      rewardTokenLogo: logo_url,
      rewardTicker: output_ticker,
      rewardDescription: reward_description,
    },
    status,
    vesting,
    vestingType,
    cliff,
  };
};

export default formatSale;
