import styled, { keyframes } from 'styled-components';

import { ReactComponent as Rocket } from 'assets/images/icon/rocket.svg';

const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const fireSmall = keyframes`
  10% {
      transform: rotate(17deg) translateY(1px)
  }
  20% {
      transform: rotate(-13deg) translateY(2px)
  }
  30% {
      transform: rotate(21deg) translateY(3px)
  }
  40% {
      transform: rotate(-34deg)translateY(4px)
  }
  50% {
      transform: rotate(24deg) translateY(5px)
  }
  60% {
      transform: rotate(-17deg) translateY(6px)
  }
  70% {
      transform: rotate(31deg) translateY(7px)
  }
  80% {
      transform: rotate(-28deg) translateY(8px)
  }
  90% {
      transform: rotate(14deg) translateY(9px)
  }
  99% {
      transform: rotate(0deg) translateY(10px)
  }
`;

const fireLeft = keyframes`
  6% {
      transform: rotate(25deg)
  }
  15% {
      transform: rotate(-19deg)
  }
  25% {
      transform: rotate(25deg)
  }
  32% {
      transform: rotate(-30deg)
  }
  46% {
      transform: rotate(22deg)
  }
  54% {
      transform: rotate(-29deg)
  }
  61% {
      transform: rotate(32deg)
  }
  74% {
      transform: rotate(-9deg)
  }
  83% {
      transform: rotate(16deg)
  }
  99% {
      transform: rotate(0deg)
  }
`;

const fireMiddle = keyframes`
  10% {
      transform: rotate(25deg)
  }
  20% {
      transform: rotate(-25deg)
  }
  30% {
      transform: rotate(30deg)
  }
  40% {
      transform: rotate(-22deg)
  }
  50% {
      transform: rotate(29deg)
  }
  60% {
      transform: rotate(-45deg)
  }
  70% {
      transform: rotate(37deg)
  }
  80% {
      transform: rotate(-15deg)
  }
  90% {
      transform: rotate(16deg)
  }
  99% {
      transform: rotate(0deg)
  }
`;

const fireRight = keyframes`
  15% {
      transform: rotate(17deg)
  }
  23% {
      transform: rotate(-13deg)
  }
  37% {
      transform: rotate(21deg)
  }
  45% {
      transform: rotate(-34deg)
  }
  54% {
      transform: rotate(24deg)
  }
  67% {
      transform: rotate(-17deg)
  }
  72% {
      transform: rotate(31deg)
  }
  84% {
      transform: rotate(-28deg)
  }
  96% {
      transform: rotate(14deg)
  }
  99% {
      transform: rotate(0deg)
  }
`;

const fillOpacity1 = keyframes`
  0% {
      fill-opacity: 1;
      stroke-opacity: 1;
  }
  50% {
      fill-opacity: 1;
      stroke-opacity: 1;
  }
  100% {
      fill-opacity: 0;
      stroke-opacity: 0;
  }
`;

const fillOpacity2 = keyframes`
  0% {
      fill-opacity: 1;
      stroke-opacity: 1;
  }
  25% {
      fill-opacity: 1;
      stroke-opacity: 1;
  }
  100% {
      fill-opacity: 0;
      stroke-opacity: 0;
  }
`;

const fillOpacity3 = keyframes`
  0% {
      fill-opacity: 1;
      stroke-opacity: 1;
  }
  67% {
      fill-opacity: 1;
      stroke-opacity: 1;
  }
  100% {
      fill-opacity: 0;
      stroke-opacity: 0;
  }
`;

const RocketLogo = styled(Rocket)`
  .rocket_inner {
    transform: translateY(15px) translateX(-3px);
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
  }
  .icon_circle {
    transition: .2s;
    fill: transparent;
  }
  .large_window_path {
    transition: .2s;
    fill: #22303e;
  }
  .small_window_path {
    fill: #22303e;
  }
  .wing_shadow {
    fill: #34495e;
  }
  .rocket_bottom {
    fill: #34495e;
  }
  .rocket_base {
    fill: #34495e;
  }
  .rocket_shadow {
    fill: #34495e;
  }
  .window_grandient {
    stop-color: #2DCB73;
  }
  .window_grandient1 {
    stop-color: #2AC16D;
  }
  .window_grandient2 {
    stop-color: #29B968;
  }
  .window_grandient3 {
    stop-color: #28B767;
  }
  .wing_base {
    fill: #34495e;
  }
  .fire_path {
    fill: #FC0;
  }

  .rocket_base {
    fill: #FFFFFF !important;
    stroke-width: 0px !important;
  }
  .rocket_shadow {
    fill: #EDEDED !important;
    stroke-width: 0px !important;
  }
  .small_window_path {
    fill: #28B767 !important;
    stroke-width: 0px !important;
  }
  .wing_shadow {
    display: block !important;
    fill: #FC9252 !important;
  }
  .wing_base {
    fill: #E16E36 !important;
    stroke-width: 0px !important;
  }
  .rocket_bottom {
    fill: #2DCB73 !important;
    stroke-width: 0px !important;
  }
  .large_window_path {
    fill: url(#SVGID_2_) !important;
    stroke-width: 0px !important;
  }
  .rocket_inner {
    transform: translateY(0px) translateX(-3px) !important;
  }

  .fire {
    display: none;
    animation-delay: 0s;
    fill-opacity: 1;
    animation-timing-function: ease-in;
    stroke-width: 0px;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: 50% 50%;
    animation-direction: normal;
  }
  #fireLeft {
    display: block;
    animation-delay: 0s;
    animation-name: ${fireLeft}, ${fillOpacity1};
    animation-duration: 1.2s;
  }
  #fireMiddle {
    display: block;
    animation-delay: 0s;
    animation-name: ${fireMiddle}, ${fillOpacity1};
    animation-duration: 1s;
  }
  #fireRight {
    display: block;
    animation-delay: 0s;
    animation-name: ${fireRight}, ${fillOpacity1};
    animation-duration: 1.3s;
  }
  #fireSmallLeft {
    display: block;
    animation-delay: 0s;
    animation-name: ${fireSmall}, ${fillOpacity2};
    animation-duration: 1.3s;
    transform-origin: bottom;
  }
  #fireSmallRight {
    display: block;
    animation-delay: 0.3s;
    animation-name: ${fireSmall}, ${fillOpacity3};
    animation-duration: 1.6s;
    transform-origin: bottom;
  }
`;

export default {
  Container,
  RocketLogo,
};
