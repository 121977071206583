import * as Yup from 'yup';

import { usn } from 'services/config';
import { VALID_VALUE_CONTRACT_ID } from 'shared/constants';

const validationSchema = Yup.object({
  projectName: Yup.string()
    .trim()
    .required('Required'),
  description: Yup.string()
    .trim()
    .required('Required'),
  depositTokenId: Yup.string()
    .trim()
    .test(
      'Is valid token id?',
      `Reward token id must contain ${VALID_VALUE_CONTRACT_ID}!`,
      (value) => {
        if (value && value.toLowerCase() === usn) return true;
        if ((value && value.includes(VALID_VALUE_CONTRACT_ID))) return true;
        return false;
      },
    )
    .required('Required'),

  maxAmount: Yup.number()
    .when('minAllocation', (minAllocation: number | undefined, schema) => (minAllocation
      ? schema.min(minAllocation, 'Must be greater than or equal Min Allocation')
      : schema.min(1, 'Must be greater than or equal 1')))
    .required('Required'),
  projectSymbol: Yup.string()
    .trim()
    .required('Required'),
  distributeTokenId: Yup.string()
    .trim()
    .test(
      'Is valid token id?',
      `Distribute token id must contain ${VALID_VALUE_CONTRACT_ID}!`,
      (value) => {
        if (value && value.toLowerCase() === usn) return true;
        if ((value && value.includes(VALID_VALUE_CONTRACT_ID))) return true;
        return false;
      },
    )
    .required('Required'),
  minAllocation: Yup.number()
    .positive('Value must be a positive number')
    .required('Required'),
  maxAllocation: Yup.number()
    .when('minAllocation', (minAllocation: number | undefined, schema) => (minAllocation
      ? schema.min(minAllocation, 'Must be greater than or equal Min Allocation')
      : schema))
    .required('Required'),
  limitPerTransaction: Yup.number()
    .when('maxAllocation', (maxAllocation: number | undefined, schema) => (maxAllocation
      ? schema.max(maxAllocation, 'Must be less than or equal Max Allocation')
      : schema.positive('Value must be a positive number')))
    .required('Required'),

  roundType: Yup.string()
    .oneOf(
      ['ByAmount', 'BySubscription'],
      'Must be ByAmount or BySubscription',
    )
    .required('Choose one of the values: Amount or Subscription'),
  price: Yup.number()
    .positive('Value must be a positive number')
    .required('Required'),

  roundStartDate: Yup.string()
    .required('Required'),
  roundFinishDate: Yup.string()
    .required('Required'),

  projectSite: Yup.string()
    .url('Must be a valid URL')
    .required('Required'),
  socialTg: Yup.string()
    .url('Must be a valid URL')
    .notRequired(),
  socialTwitter: Yup.string()
    .url('Must be a valid URL')
    .notRequired(),
  socialMedium: Yup.string()
    .url('Must be a valid URL')
    .notRequired(),
});

export default validationSchema;
