const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const buildURI = (list: string) => {
  const csv = list;
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob(['\uFEFF', csv], { type });
  const dataURI = `data:${type};charset=utf-8,\uFEFF${csv}`;

  const URL = window.URL || window.webkitURL;
  return (typeof URL.createObjectURL === 'undefined')
    ? dataURI
    : URL.createObjectURL(blob);
};

const downloadFile = (list: string, saleId: number) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = buildURI(list);
  downloadLink.download = `Bocachica_mars__${saleId}__.csv`;
  downloadLink.target = '_self';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default downloadFile;
