import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

const TextInput = styled(TextField)`
  color: ${({ theme }) => theme.black};
  margin: 0.625rem 0!important;
`;

const ErrorBlock = styled.div`
  font-size: 0.75rem;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.error};
`;

const TextArea = styled.textarea`
  resize: none;
  height: 6.25rem;
  border-radius: .25rem;
  ::placeholder {
    font-size: 1rem;
  }
`;

const StyledSelect = styled(NativeSelect)`
  margin-bottom: .5rem!important;
`;

export default {
  TextInput,
  ErrorBlock,
  TextArea,
  StyledSelect,
};
