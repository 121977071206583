import Big from 'big.js';
import { Fragment } from 'react';

import { ONE_HUNDRED } from 'shared/constants';
import { ETypeVesting, IVesting } from 'shared/interfaces';

import styles from './styles';

interface IVestingSchedule {
  vesting: IVesting[],
  vestingType: ETypeVesting | null,
  totalClaimAvailable?: string,
}
interface IVestingScheduleWrapper {
  children: JSX.Element | JSX.Element[],
  label?: string
}

export function VestingScheduleWrapper({
  children,
  label,
}: IVestingScheduleWrapper) {
  return (
    <styles.Container>
      <styles.Title>
        Token Vesting Schedule
      </styles.Title>
      {label && (
      <styles.Label>
        {label}
      </styles.Label>
      )}
      <styles.Vesting>
        {children}
      </styles.Vesting>
    </styles.Container>
  );
}

export default function VestingSchedule({
  vesting,
  vestingType,
  totalClaimAvailable,
}: IVestingSchedule) {
  switch (vestingType) {
    case ETypeVesting.OneTime:
      return (
        <VestingScheduleWrapper>
          {vesting.map((row) => (
            <Fragment key={row.date}>
              <styles.Row isOneTimeVesting>
                <span>
                  {
                    totalClaimAvailable
                      ? `Unlock Date (${totalClaimAvailable})`
                      : 'Unlock Date'
                  }
                </span>
                <span>
                  {`${row.dateObj.day} 
                  ${row.dateObj.month} 
                  ${row.dateObj.year} 
                  ${row.dateObj.hours}:${row.dateObj.minutes}
                  `}
                </span>
              </styles.Row>
              <styles.Division />
            </Fragment>
          ))}
        </VestingScheduleWrapper>
      );
    default:
      return (
        <VestingScheduleWrapper label="Unlock Date">
          {vesting.map((row, index) => {
            const amount = totalClaimAvailable
              ? Big(totalClaimAvailable).mul(row.quota).div(ONE_HUNDRED).toFixed()
              : '';
            return (
              <Fragment key={row.date}>
                <styles.Row>
                  <span>
                    {totalClaimAvailable
                      ? `${index + 1}st unlock (${row.quota}%)`
                      : `${index + 1}st unlock (${row.quota}%, ${amount})`}
                  </span>
                  <span>
                    {`${row.dateObj.day} 
                  ${row.dateObj.month} 
                  ${row.dateObj.year} 
                  ${row.dateObj.hours}:${row.dateObj.minutes}
                  `}
                  </span>
                </styles.Row>
                <styles.Division />
              </Fragment>
            );
          })}
        </VestingScheduleWrapper>
      );
  }
}
