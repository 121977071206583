import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import { contractId } from 'services/config';
import { FungibleTokenContract, SaleContract } from 'services/contract';
import { Action, ITokenMetadata, VestingOutput } from 'services/interfaces';
import { IFormattedValues } from 'shared/interfaces';
import { downloadFile, generateList } from 'shared/utils';

import { retrieveSaleAccountsArray } from './helpers';
import { ISale, ServiceContextType } from './interfaces';
import { useWalletData } from './WalletProvider';

const ServiceContextHOC = createContext<ServiceContextType>({} as ServiceContextType);

export function ServiceProvider({ children }:{ children: JSX.Element }) {
  const { sendTransaction, RPCProvider } = useWalletData();

  const [saleContract, setSaleContract] = useState<SaleContract | undefined>();

  useEffect(() => {
    const saleInstance = new SaleContract(contractId, RPCProvider);
    setSaleContract(saleInstance);
  }, [RPCProvider]);

  const createSale = useCallback(async (values: IFormattedValues, token: FungibleTokenContract) => {
    if (!saleContract) return;
    const actions: Action[] = [];
    const createSaleTransaction = saleContract.createSale(values);
    actions.push(createSaleTransaction);

    const storageDeposit = await token.checkStorageBalance(({ accountId: contractId }));
    if (storageDeposit) actions.push(storageDeposit);

    await sendTransaction(actions);
  }, [saleContract, sendTransaction]);

  const removeSale = useCallback(async (saleId: number) => {
    if (!saleContract) return;
    const action = saleContract.removeSale(saleId);
    await sendTransaction([action]);
  }, [saleContract, sendTransaction]);

  const updateSaleDates = useCallback(async (saleId: number, startDate: number, endDate: number) => {
    if (!saleContract) return;
    const action = saleContract.updateSaleDates(saleId, startDate, endDate);
    await sendTransaction([action]);
  }, [saleContract, sendTransaction]);

  const updateSaleDistributeTokenId = useCallback(async (saleId: number, distributeTokenId: string) => {
    if (!saleContract) return;
    const action = saleContract.updateSaleDistributeTokenId(saleId, distributeTokenId);
    await sendTransaction([action]);
  }, [saleContract, sendTransaction]);

  const updateSalePrice = useCallback(async (saleId: number, price: string, totalAmount: string) => {
    if (!saleContract) return;
    const action = saleContract.updateSalePrice(saleId, price, totalAmount);
    await sendTransaction([action]);
  }, [saleContract, sendTransaction]);

  const updateSaleClaimAndRefund = useCallback(async (
    saleId: number,
    claimAvailable: boolean,
    refundAvailable: boolean,
    sale: ISale,
  ) => {
    if (!saleContract) return;
    const actions: Action[] = [];
    if (sale.claimAvailable !== claimAvailable) {
      actions.push(saleContract.updateSaleClaimAvailable(saleId, claimAvailable));
    }
    if (sale.refundAvailable !== refundAvailable) {
      actions.push(saleContract.updateSaleRefundAvailable(saleId, refundAvailable));
    }
    if (actions.length === 0) return;
    await sendTransaction(actions);
  }, [saleContract, sendTransaction]);

  const downloadSaleAccounts = useCallback(async (
    saleId: number,
    numAccountSales: number,
    metadata: ITokenMetadata,
  ) => {
    if (!saleContract) return;
    const resultsArray = await retrieveSaleAccountsArray(saleContract, saleId, numAccountSales);
    const list = generateList(resultsArray, metadata);
    downloadFile(list, saleId);
  }, [saleContract]);

  const changeVesting = useCallback(async (saleId: number, inputVesting: VestingOutput[]) => {
    if (!saleContract) return;
    const action = saleContract.changeVesting(saleId, inputVesting);
    await sendTransaction([action]);
  }, [saleContract, sendTransaction]);

  const serviceData = useMemo(() => ({
    saleContract,
    createSale,
    removeSale,
    updateSaleDates,
    updateSaleDistributeTokenId,
    updateSalePrice,
    updateSaleClaimAndRefund,
    downloadSaleAccounts,
    changeVesting,
  }), [saleContract]);

  return (
    <ServiceContextHOC.Provider value={serviceData}>
      {children}
    </ServiceContextHOC.Provider>
  );
}

export const useService = () => useContext(ServiceContextHOC);
