import { Form } from 'formik';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3.125rem;
  & > button {
    border-radius: 10px;
    font-size: 1rem;
  }
`;
const VestingWrapper = styled.div`
  padding: 1.125rem;
  background-color: white;
  border-radius: 20px;
`;

export default {
  Container,
  FormContainer,
  VestingWrapper,
};
