import { Button } from '@mui/material';
import styled from 'styled-components';

const ErrorBlock = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.error};
`;

const TitleMetadata = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    margin-left: .25rem;
    border-radius: 12px;
    height: 19px;
    width: 19px;
  }
`;

export default {
  ErrorBlock,
  Button,
  TitleMetadata,
};
