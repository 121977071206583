import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useState } from 'react';

import { useService } from 'providers/ServiceProvider';
import { useWalletData } from 'providers/WalletProvider';
import { VestingOutput } from 'services/interfaces';
import {
  GeneralInformation, RoundInformation, RoundDate, Network,
} from 'shared/components/FormComponent';
import FormComponentStyles from 'shared/components/FormComponent/styles';
import LoadingRocket from 'shared/components/LoadingRocket';
import SaleCard from 'shared/components/SaleCard';
import VestingAdminPanel from 'shared/components/VestingAdminPanel';
import { EFromValues, IValues } from 'shared/interfaces';
import { formatValues, initialValues, validationSchema } from 'shared/utils';

import styles from './styles';

export default function CreateSalePage() {
  const { isSignedIn } = useWalletData();
  const { createSale } = useService();
  const [isValidVesting, setIsValidVesting] = useState<boolean>(true);
  const handleSubmit = async (values: IValues) => {
    if (!values.depositToken?.metadata || !values.distributeToken?.metadata || !isValidVesting) return;
    const formattedValues = formatValues(values, values.depositToken.metadata, values.distributeToken.metadata);
    await createSale(formattedValues, values.depositToken);
  };

  return (
    isSignedIn
      ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <styles.Container>
              <styles.FormContainer>
                <GeneralInformation
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <RoundInformation
                  values={values}
                />
                <RoundDate
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <FormComponentStyles.Wrapper>
                  <FormComponentStyles.Title>Vesting</FormComponentStyles.Title>
                  <styles.VestingWrapper>
                    <VestingAdminPanel
                      initialVestingType={values.vestingType}
                      saleEndDate={dayjs(values.roundFinishDate).valueOf()}
                      saleTotalAmount={values.maxAmount}
                      setIsValidVesting={setIsValidVesting}
                      currentVesting={values.vesting}
                      handleChange={(vesting: VestingOutput[]) => setFieldValue(EFromValues.vesting, vesting)}
                    />
                  </styles.VestingWrapper>
                </FormComponentStyles.Wrapper>
                <Network />
                <Button
                  variant="contained"
                  color="warning"
                  type="submit"
                  disabled={!isSignedIn}
                >
                  Create Sale
                </Button>
              </styles.FormContainer>
              <SaleCard
                values={values}
              />
            </styles.Container>
          )}
        </Formik>
      )
      : (
        <LoadingRocket />
      )
  );
}
