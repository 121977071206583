import useCountdown from 'shared/hooks/useCountdown';

import styles from './styles';

interface ITimestampModel {
  time: number;
}

export default function Timestamp({ time } : ITimestampModel) {
  const countdown = useCountdown(time);
  const timeStr = `${countdown.hours} : ${countdown.minutes} : ${countdown.seconds}`;
  return (
    <styles.Time>
      {`${countdown.days}d`}
      <styles.Divider />
      {timeStr}
    </styles.Time>
  );
}
