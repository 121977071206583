import DateAdapter from '@mui/lab/AdapterDayjs';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { createFormattedDate } from 'services/helpers';
import { EFromValues, IValues } from 'shared/interfaces';

import styles from './styles';

interface IRoundDate {
  values: IValues,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
}

export default function RoundDate({ values, setFieldValue }: IRoundDate) {
  return (
    <styles.Wrapper>
      <styles.Title>Round Date</styles.Title>
      <styles.ChildWrapper>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DateTimePicker
            renderInput={(props: TextFieldProps) => <TextField {...props} />}
            label="Round Start Date (UTC)"
            value={values.roundStartDate}
            onChange={(newValue:string | null) => {
              setFieldValue(
                EFromValues.roundStartDate,
                createFormattedDate(newValue),
              );
            }}
            ampm={false}
          />
          <DateTimePicker
            renderInput={(props: TextFieldProps) => <TextField {...props} />}
            label="Round End Date (UTC)"
            value={values.roundFinishDate}
            onChange={(newValue:string | null) => {
              setFieldValue(
                EFromValues.roundFinishDate,
                createFormattedDate(newValue),
              );
            }}
            ampm={false}
          />
        </LocalizationProvider>
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
