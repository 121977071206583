import { IFormattedValues } from 'shared/interfaces';

import { Action } from './index';

export interface ISaleContract {
  getNumSales(): Promise<number | undefined>,
  getSales(from: number, limit: number): Promise<SaleOutput[] | undefined>,
  getSaleAccounts(saleId: number, from: number, limit: number): Promise<ISaleAccounts[]>,
  removeSale(saleId: number): Action,
  createSale(values: IFormattedValues): Action,
  updateSaleDates(saleId: number, startDate: number, endDate: number): Action,
  updateSaleDistributeTokenId(saleId: number, distributeTokenId: string): Action,
  updateSalePrice(saleId: number, price: string, totalAmount: string): Action,
  updateSaleClaimAvailable(saleId: number, claimAvailable: boolean): Action,
  updateSaleRefundAvailable(saleId: number, refundAvailable: boolean): Action,
}

export enum ESaleType {
  /// First buyers receive tokens. Sale stops when collected_amount reached
  ByAmount = 'ByAmount',
  /// Unlimited purchase, proportional distribution. Sale stops when end_date reached
  BySubscription = 'BySubscription',
}

export interface VestingOutput {
  steps: number,
  quota: number,
  date: number,
}

interface SaleMetadata {
  /// Project name that is going to be on sale.
  name: string,
  /// Symbol (ticker) for the token on sale.
  symbol: string,
  /// Project description.
  description: string,
  /// Link to project smart contract.
  smart_contract_url: string,
  /// Project logo.
  logo_url: string,
  /// Symbol for output token
  output_ticker: string,
  /// Social medias of the project
  project_telegram: string | null,
  project_medium: string | null,
  project_twitter: string | null,
  reward_timestamp: number | null,
  reward_description: string | null,
}

export interface SaleOutput {
  sale_id: number,
  metadata: SaleMetadata,
  staking_contracts: string[],
  min_near_deposit: string,
  deposit_token_id: string,
  claim_available: boolean,
  refund_available: boolean,
  distribute_token_id: string | null,
  distribute_token_decimals: number | null,
  min_buy: string,
  max_buy: string,
  max_amount: string,
  start_date: string,
  end_date: string,
  price: string,
  whitelist_hash: string[] | null,
  limit_per_transaction: string,
  collected_amount: string,
  num_account_sales: number,
  sale_type: ESaleType,
  claim_begun: boolean,
  vesting: VestingOutput[] | null
}

export interface ISaleAccounts {
  0: string;
  1: {
    amount: string;
    amount_to_claim: string;
    claimed: string;
    refund: string;
    refunded: string;
  };
}
