import Big from 'big.js';
import dayjs from 'dayjs';

import { Cliff } from 'providers/interfaces';
import { VestingOutput } from 'services/interfaces';
import { ERROR_DATE, ONE_HUNDRED, ZERO_STR } from 'shared/constants';
import {
  ETypeVesting, EPeriodsErrors, IPeriod, IVesting, EVestingStatus, IVestingDetails, IVestingArray,
} from 'shared/interfaces';

import { nanosecondsToMilliseconds, prefixKiloToSI, systemSIToPrefixKilo } from './formatAmount';
import { isNullOrEmpty, millisecondsToNanoseconds, parseDate } from './index';

export const initialPeriod = {
  percent: '',
  startPeriod: '',
  endPeriod: '',
  steps: '',
};

export const emptyPeriods = {
  steps: '',
  percent: '',
  endPeriod: '',
};

export const filterPeriods = (periods: IPeriod[]) => {
  const filteredPeriod = periods.filter((period, index) => {
    if (isNullOrEmpty(period.percent)
    || isNullOrEmpty(period.steps)
    || (isNullOrEmpty(period.endPeriod) || period.endPeriod === ERROR_DATE)
    || (isNullOrEmpty(period.percent) && index === 0)) return null;
    return period;
  });
  if (filteredPeriod.length === 0) return null;
  return filteredPeriod;
};

export const formatPeriods = (periods: IPeriod[]) => (periods.map((period) => {
  const endValueOf = dayjs.utc(period.endPeriod).valueOf();
  const endPeriod = millisecondsToNanoseconds(endValueOf);
  const percent = systemSIToPrefixKilo(Number(period.percent));
  const steps = Number(period.steps);
  if (period.startPeriod) {
    const startValueOf = dayjs.utc(period.startPeriod).valueOf();
    const startPeriod = millisecondsToNanoseconds(startValueOf);
    return {
      steps,
      percent,
      startPeriod,
      endPeriod,
    };
  }
  return {
    steps,
    percent,
    endPeriod,
  };
})
);

export const validationPeriods = (
  periods: IPeriod[],
  vestingType?: ETypeVesting,
  saleEndDate?: number,
) => {
  const formattedPeriod = periods.map((period) => ({
    ...period,
    startPeriod: period.startPeriod ? dayjs(period.startPeriod).valueOf() : null,
    endPeriod: dayjs(period.endPeriod).valueOf(),
  }));

  const dateForCheck = saleEndDate ? dayjs(saleEndDate).valueOf() : Date.now();
  switch (vestingType) {
    case ETypeVesting.OneTime: {
      const invalidDistributionDate = formattedPeriod.every(
        (period) => period.endPeriod < dateForCheck,
      );
      return invalidDistributionDate ? EPeriodsErrors.invalidDistributionDate : null;
    }

    case ETypeVesting.Stepwise: {
      const periodsDate = formattedPeriod.map((period) => period.endPeriod);
      const invalidDistributionDate = formattedPeriod.some((period, index) => {
        if (period.startPeriod && period.startPeriod < dateForCheck) return true;
        const previousPeriodsDate = period.startPeriod
          ? period.startPeriod
          : periodsDate[index - 1];
        return period.endPeriod < previousPeriodsDate;
      });
      if (invalidDistributionDate) return EPeriodsErrors.invalidDistributionDate;
      const totalPercent = periods.reduce((previousValue, period) => previousValue + Number(period.percent), 0);
      if (totalPercent !== ONE_HUNDRED) return EPeriodsErrors.invalidPercentAmount;
      return null;
    }
    default: return null;
  }
};

export const retrieveVesting = (
  periods: IPeriod[],
  vestingType?: ETypeVesting,
  saleEndDate?: number,
): {
  vestingArray: VestingOutput[],
  error: EPeriodsErrors | null,
} | null => {
  const filteredPeriods = filterPeriods(periods);

  if (!filteredPeriods) return null;

  const error = validationPeriods(filteredPeriods, vestingType, saleEndDate);

  const formattedPeriods = formatPeriods(filteredPeriods);
  let vestingArray: VestingOutput[] = [];

  switch (vestingType) {
    case ETypeVesting.OneTime: {
      if (formattedPeriods.length !== 1) return null;
      vestingArray = formattedPeriods.map((vesting) => ({
        steps: vesting.steps,
        quota: vesting.percent,
        date: vesting.endPeriod,
      }));
      break;
    }
    case ETypeVesting.Stepwise: {
      const firstElement = formattedPeriods[0];
      if (!firstElement.startPeriod) return null;
      const cliff = [{
        steps: 1,
        quota: 0,
        date: firstElement.startPeriod,
      }];
      const currVesting: VestingOutput[] = formattedPeriods.map((vesting) => ({
        steps: vesting.steps,
        quota: vesting.percent,
        date: vesting.endPeriod,
      }));
      vestingArray = cliff.concat(currVesting);
      break;
    }
    default: return null;
  }
  return {
    vestingArray,
    error,
  };
};

export const getVestingArray = (
  vesting: VestingOutput[] | null,
  vestingType: ETypeVesting | null,
  saleEndDate: number,
): IVesting[] | null => {
  if (!vesting) return null;
  switch (vestingType) {
    case ETypeVesting.OneTime:
      return vesting
        .map((el) => {
          const dateObj = parseDate(el.date);
          return {
            ...el,
            dateObj,
          };
        });
    case ETypeVesting.Stepwise: {
      const vestingDate = vesting.map((el) => (el.date));
      const vestingArray: IVesting[] = vesting
        .map((vestingItem, index) => {
          const previousVestingDate = vestingDate[index - 1]
            ? vestingDate[index - 1]
            : saleEndDate;
          if (vestingItem.steps > 1) {
            const progressionDifference = Math.ceil(vestingDate[index] - previousVestingDate) / vestingItem.steps;
            const quotaPerStep = vestingItem.quota / vestingItem.steps;
            let i = previousVestingDate;
            const newElement: IVesting[] = [];
            for (let stepIndex = 0; stepIndex < vestingItem.steps; stepIndex += 1) {
              const date = i + progressionDifference;
              const newDateObj = parseDate(date);
              newElement.push({
                previousVestingDate: i,
                steps: 1,
                quota: quotaPerStep,
                date,
                dateObj: newDateObj,
              });
              i += progressionDifference;
            }
            return newElement;
          }
          const dateObj = parseDate(vestingItem.date);
          return {
            ...vestingItem,
            previousVestingDate,
            dateObj,
          };
        })
        .flat()
        .filter((el) => el.quota !== 0);
      return vestingArray;
    }
    default: {
      return null;
    }
  }
};

export const formatVesting = (vesting: VestingOutput[] | null): VestingOutput[] | null => {
  if (!vesting) return null;
  return (vesting.map((el) => {
    const date = nanosecondsToMilliseconds(el.date);
    const quota = prefixKiloToSI(el.quota);
    return {
      ...el,
      quota,
      date,
    };
  })
  );
};

export const getVestingType = (vesting: VestingOutput[] | null): ETypeVesting => (
  vesting && vesting.length === 1 && vesting.every((el) => el.steps === 1)
    ? ETypeVesting.OneTime
    : ETypeVesting.Stepwise
);

export const getCliffDetails = (formattedVesting: VestingOutput[] | null): Cliff | null => {
  if (!formattedVesting) return null;
  const cliff = formattedVesting[0] || null;
  if (!cliff || formattedVesting[0].quota !== 0) return null;
  return {
    active: cliff.date > Date.now(),
    timestamp: cliff.date,
  };
};

export const detectedVestingStatus = (
  vestingDate: number,
  vestingType: ETypeVesting | null,
  previousVestingDate?: number,
): EVestingStatus => {
  const currentDate = Date.now();
  switch (vestingType) {
    case ETypeVesting.OneTime: {
      if (currentDate < vestingDate) return EVestingStatus.ACTIVE;
      return EVestingStatus.CLOSED;
    }
    case ETypeVesting.Stepwise: {
      if (!previousVestingDate && vestingDate > currentDate) return EVestingStatus.ACTIVE;
      if (
        previousVestingDate
        && previousVestingDate < currentDate
        && vestingDate > currentDate
      ) return EVestingStatus.ACTIVE;
      if (previousVestingDate && previousVestingDate > currentDate) return EVestingStatus.SOON;

      return EVestingStatus.CLOSED;
    }
    default: {
      return EVestingStatus.CLOSED;
    }
  }
};

export const getAmountTokensArray = (
  totalLocked: string,
  availableTokens: string,
) => [
  {
    title: 'Total Locked',
    value: totalLocked,
  },
  {
    title: 'Available Tokens',
    value: availableTokens,
  },
];

export const getVestingDetails = (
  vestingArray: IVesting[],
  vestingType: ETypeVesting | null,
  totalClaimAvailable: string,
): IVestingDetails => {
  const vestingArrayWithClaim: IVestingArray[] = vestingArray.map((vesting) => {
    const amount = Big(totalClaimAvailable).mul(vesting.quota).div(ONE_HUNDRED).toFixed();
    const status = detectedVestingStatus(vesting.date, vestingType, vesting.previousVestingDate);
    switch (status) {
      case EVestingStatus.CLOSED: {
        return {
          ...vesting,
          amount,
          status: EVestingStatus.AVAILABLE_TO_CLAIM,
        };
      }
      default:
        return {
          ...vesting,
          amount,
          status,
        };
    }
  });

  const availableTokens = vestingArrayWithClaim.reduce((acc: string, vestingItem) => {
    if (vestingItem.status === EVestingStatus.AVAILABLE_TO_CLAIM) {
      return Big(vestingItem.amount).plus(acc).toFixed();
    }
    return acc;
  }, ZERO_STR);
  const totalLocked = Big(totalClaimAvailable).minus(availableTokens).toFixed();
  const tokensArray = getAmountTokensArray(
    totalLocked,
    availableTokens,
  );

  return {
    totalLocked,
    availableTokens,
    array: vestingArrayWithClaim,
    tokensArray,
  };
};

export const detectActiveVesting = (
  vestingArray: IVestingArray[] | null,
  vestingType: ETypeVesting | null,
): IVesting | null => {
  if (!vestingArray) return null;
  switch (vestingType) {
    case ETypeVesting.OneTime: {
      const vesting = vestingArray[0];
      return vesting.status === EVestingStatus.ACTIVE ? vesting : null;
    }
    case ETypeVesting.Stepwise: {
      const activeVesting = vestingArray.find((el) => el.status === EVestingStatus.ACTIVE);
      return activeVesting || null;
    }
    default: return null;
  }
};

export const updateVestingStatus = (
  vestingArray: IVesting[],
  vestingType: ETypeVesting | null,
) => vestingArray.map((vesting) => ({
  ...vesting,
  status: detectedVestingStatus(vesting.date, vestingType, vesting.previousVestingDate),
}));
