import styled from 'styled-components';

export const AmountBlock = styled.div`
  margin-top: 3rem;
  progress {
    margin: .75rem 0;
    width: 100%;
    height: 1rem;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: .25rem;
    ::-webkit-progress-bar {
      background: rgba(${({ theme }) => theme.orangeRGB}, 0.2);
      border-radius: .25rem;
    }
    ::-webkit-progress-value {
      background: rgba(${({ theme }) => theme.orangeRGB});
      border-radius: .25rem;
    }
  }
`;

const Title = styled.div`
  display: flex;
  font-weight: 500;
  font-size: .75rem;
  color: ${({ theme }) => theme.primaryGrey};
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.black};
`;

export default {
  AmountBlock,
  Title,
  Label,
};
