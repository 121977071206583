import { DASH } from 'shared/constants';
import { IParseDate } from 'shared/interfaces';
import { parseDate } from 'shared/utils';

import SaleDataStyles from './styles';

interface ISaleDate {
  startDate: number;
  endDate: number;
}

const getLongDate = (parsedDate: IParseDate): string => `
  ${parsedDate.month} 
  ${parsedDate.day} 
  ${parsedDate.year} 
  ${parsedDate.hours}:${parsedDate.minutes} UTC
`;
export default function SaleDate(
  {
    startDate,
    endDate,
  }: ISaleDate,
) {
  const parseStartDate = parseDate(new Date(startDate).toString());
  const parseEndDate = parseDate(new Date(endDate).toString());

  const startStr = startDate === 0
    ? DASH
    : getLongDate(parseStartDate);
  const finishStr = endDate === 0
    ? DASH
    : getLongDate(parseEndDate);
  return (
    <SaleDataStyles.DeadlineWrapper>
      <div>
        <span>
          Start
        </span>
        <p>
          {startStr}
        </p>
      </div>
      <div>
        <span>
          Finish
        </span>
        <p>
          {finishStr}
        </p>
      </div>
    </SaleDataStyles.DeadlineWrapper>
  );
}
