import { useEffect, useState } from 'react';

import { ONE_SECOND } from 'shared/constants';
import { formatCountdown } from 'shared/utils';

const useCountdown = (deadline: number) => {
  const [countdownInSeconds, setCountdownInSeconds] = useState<number>(deadline - Date.now());
  useEffect(() => {
    const timerId = setInterval(() => {
      if (Date.now() > deadline) return;
      setCountdownInSeconds(deadline - Date.now());
    }, ONE_SECOND);
    return () => clearInterval(timerId);
  });
  return formatCountdown(countdownInSeconds);
};

export default useCountdown;
