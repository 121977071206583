export { default as initialValues } from './initialValues';
export { default as validationSchema } from './validationSchema';
export { default as formatSale } from './formatSale';
export { default as getParticipantData } from './getParticipantData';
export { default as downloadFile } from './downloadFile';
export { default as generateList } from './generateList';
export { default as checkVestingShouldUpdate } from './checkShouldUpdate';
export * from './formatValues';
export * from './formatAmount';
export * from './displayAmount';
export * from './adminPanel';
export * from './vesting';
export * from './calculationDate';
export * from './socialLinks';
export * from './statusLocales';

export const getUpperCase = (value:string) => value.toUpperCase();
export const getLowerCase = (value:string) => value.toLowerCase();

export const isNullOrEmpty = (str: string | null | undefined) => (
  str?.length === 0 || str === null || str === undefined
);

export const copy = (text: string) => navigator.clipboard.writeText(text);

export function isNotNullOrUndefined<T extends Object>(input: null | undefined | T): input is T {
  return input != null;
}

export const onlyUniqueValues = (values: string[]) => Array.from(new Set(values));

export const toArray = (map: { [key: string]: any }) => Object.values(map);
export const toMap = (array: any[]) => array.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
