import styled from 'styled-components';

import { ReactComponent as ClearSearchIcon } from 'assets/images/icon/clear-search.svg';
import { ReactComponent as RocketIcon } from 'assets/images/icon/rocket-icon.svg';
import { ReactComponent as SearchIcon } from 'assets/images/icon/search.svg';

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  max-width: 43rem;
  width: 100%;
  transition: all 1s ease;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 2rem;
    max-width: 100%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 1rem;
  `}
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: .75rem;
  line-height: .938rem;
  margin-top: 2.5rem;
  margin-bottom: 1.75rem;
  align-self: center;
`;

const WrapperCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
`;

const StyledInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 30%;
  border: 2px solid ${({ theme }) => theme.grey};
  border-radius: 8px;
  min-height: 48px;
  :focus-within {
    border: 2px solid ${({ theme }) => theme.orange};
  }
`;

const Input = styled.input`
  margin: 0 48px;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.white};
`;

const ClearSearch = styled(ClearSearchIcon)`
  position: absolute;
  width: 22px;
  height: 22px;
  right: 14px;
  fill: ${({ theme }) => theme.grey};
  :hover {
    fill: ${({ theme }) => theme.white};
  }
`;

const Search = styled(SearchIcon)`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 14px;
  fill: ${({ theme }) => theme.grey};
`;

const Loading = styled(RocketIcon)`
  margin-top: 3rem;
  display: flex;
  align-self: center;
  max-width: 300px;
  max-height: 300px;
`;

export default {
  Container,
  Header,
  WrapperCards,
  StyledInput,
  Input,
  ClearSearch,
  Search,
  Loading,
};
