import styled from 'styled-components';

const TimerBlock = styled.div<{ isVesting?: boolean }>`
  height: 1.75rem;
  border-radius: .25rem;
  width: 100%;
  max-width: ${({ isVesting }) => (isVesting ? '23.125rem' : '17rem')};
  margin-top: ${({ isVesting }) => (isVesting ? '1.125rem' : '0')};
  background: ${({ isVesting, theme }) => (isVesting ? 'transparent' : `rgba(${theme.bgGrey}, 0.2)`)};
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 .5rem;
  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.primaryGrey};
    margin-right: .25rem;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
`;

const Time = styled.div`
  display: flex;
  min-width: 8.125rem;
  font-weight: 600;
`;

const Divider = styled.div`
  width: .063rem;
  height: 1.188rem;
  background: ${({ theme }) => theme.primaryGrey};
  margin: 0 .5rem;
  border-radius: 1px;
`;

export default {
  TimerBlock,
  Time,
  Divider,
};
