import Big from 'big.js';

import { DASH } from 'shared/constants';

import { formatTokenAmount } from './formatAmount';

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes('.') || amount.includes(',')) {
    return amount.replace(/\.?0*$/, '');
  }
  return amount;
};

export const displayAmount = (amount: string, decimals?: number) => {
  if (!decimals) return amount;
  const formateAmount = formatTokenAmount(amount, decimals);
  const amountBig = new Big(formateAmount);
  if (amountBig.eq('0')) return DASH;
  if (amountBig.lte('0.01')) return '>0.01';
  return `${removeTrailingZeros(amountBig.toFixed(3))}`;
};
