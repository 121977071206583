import { IConfig } from '../config';

export default {
  networkId: 'mainnet',
  nodeUrl: 'https://rpc.mainnet.near.org',
  walletUrl: 'https://wallet.near.org',
  helperUrl: 'https://helper.mainnet.near.org',
  explorerUrl: 'https://explorer.mainnet.near.org',
  contractId: 'launchpad.bocachica_mars.near',
  wNearAddress: 'wrap.near',
  walletId: 'near',
  myNearWalletUrl: 'https://app.mynearwallet.com/',
  usn: 'usn',
} as IConfig;
