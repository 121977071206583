import { ISale } from 'providers/interfaces';
import { FungibleTokenContract, SaleContract } from 'services/contract';
import { SaleOutput } from 'services/interfaces';
import { IRPCProviderService } from 'services/RPCProviderService';
import { DEFAULT_PAGE_LIMIT } from 'shared/constants';
import {
  formatSale,
  isNotNullOrUndefined,
  onlyUniqueValues,
  toMap,
} from 'shared/utils';

function assertFulfilled<T>(item: PromiseSettledResult<T>): item is PromiseFulfilledResult<T> {
  return item.status === 'fulfilled';
}
export async function retrieveSaleResult(pages: number, contract: SaleContract) {
  return (await Promise.allSettled(
    [...Array(pages)]
      .map((_, i) => contract.getSales(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT)),
  )).filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveSaleArray(contract: SaleContract) {
  const salesLength = await contract.getNumSales();
  const pages = Math.ceil(salesLength ? salesLength / DEFAULT_PAGE_LIMIT : 0);
  const salesResult = await retrieveSaleResult(pages, contract);
  const saleArray = salesResult
    .filter(isNotNullOrUndefined)
    .map((sale: SaleOutput) => formatSale(sale));
  return saleArray;
}

export function retrieveTokenAddresses(sales: ISale[]): string[] {
  return Array.from(
    new Set([...sales.flatMap((sale) => sale.depositTokeId)]),
  );
}

export async function retrieveFilteredTokenMetadata(
  RPCProvider: IRPCProviderService,
  tokenAddresses: string[],
):Promise<FungibleTokenContract[]> {
  return Promise.all(tokenAddresses.map(async (address: string) => {
    const ftTokenContract: FungibleTokenContract = new FungibleTokenContract(
      { provider: RPCProvider, contractId: address },
    );
    await ftTokenContract.getMetadata();
    return ftTokenContract;
  }));
}

export const getToken = (
  tokenId: string,
  tokens: { [key: string]: FungibleTokenContract },
): FungibleTokenContract | null => (tokenId ? tokens[tokenId] ?? null : null);

export async function retrieveInitialData(
  RPCProvider: IRPCProviderService,
  saleContract: SaleContract,
) {
  const saleArray = await retrieveSaleArray(saleContract);
  const tokenAddresses = retrieveTokenAddresses(saleArray);
  const tokensMetadataFiltered = await retrieveFilteredTokenMetadata(RPCProvider, onlyUniqueValues(tokenAddresses));
  const tokenMetadataMap = tokensMetadataFiltered.reduce((acc, curr) => ({ ...acc, [curr.contractId]: curr }), {});
  const newSaleArray = saleArray.filter(isNotNullOrUndefined);
  const salesMap = toMap(newSaleArray);
  return {
    salesMap,
    tokenMetadataMap,
  };
}

export async function retrieveSaleAccountsResult(pages: number, contract: SaleContract, saleId: number) {
  return (await Promise.allSettled(
    [...Array(pages)]
      .map((_, i) => contract.getSaleAccounts(saleId, i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT)),
  )).filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveSaleAccountsArray(contract: SaleContract, saleId: number, numAccountSales: number) {
  const pages = Math.ceil(numAccountSales / DEFAULT_PAGE_LIMIT);
  const resultsArray = await Promise.allSettled(
    [...Array(pages)]
      .map((_, i) => contract.getSaleAccounts(saleId, i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT)),
  );
  const filteredResultsArray = resultsArray
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
  return filteredResultsArray;
}

export const retrieveMetadataToken = async (
  tokenId: string | null,
  RPCProvider: IRPCProviderService,
  tokens: { [key: string]: FungibleTokenContract; },
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoadingTokenFailed: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (!tokenId) {
    setIsLoadingTokenFailed(true);
    setLoading(false);
    return null;
  }
  const uploadedToken = getToken(tokenId, tokens);
  if (uploadedToken) {
    setIsLoadingTokenFailed(false);
    setLoading(false);
    return uploadedToken;
  }
  try {
    const ftTokenContract: FungibleTokenContract = new FungibleTokenContract(
      { provider: RPCProvider, contractId: tokenId },
    );
    const tokenMetadata = await ftTokenContract.getMetadata();
    const isLoadingSuccessfully = Boolean(tokenMetadata);

    setIsLoadingTokenFailed(!isLoadingSuccessfully);
    if (isLoadingSuccessfully) return ftTokenContract;
    return null;
  } catch (e) {
    console.warn(`Error: ${e} while getMetadataToken`);
    return null;
  } finally {
    setLoading(false);
  }
};
