import { Cliff } from 'providers/interfaces';
import useVesting from 'shared/hooks/useVesting';
import { ETypeVesting, IVesting } from 'shared/interfaces';

import Timer from '../Timer';
import styles from './styles';
import VestingList from './VestingList';

interface IVestingSection {
  rewardTicker: string,
  vesting: IVesting[],
  vestingType: ETypeVesting | null,
  cliff: Cliff | null,
  totalClaimAvailable: string,
}

export default function VestingSection({
  rewardTicker,
  vesting,
  vestingType,
  cliff,
  totalClaimAvailable,
}: IVestingSection) {
  const {
    timestamp,
    vestingEnded,
    vestingDetails,
  } = useVesting(vesting, vestingType, totalClaimAvailable);

  if (cliff?.active) {
    return (
      <styles.DistributionInContainer>
        <Timer
          time={cliff.timestamp}
          isDistributionIn={cliff.active}
        />
      </styles.DistributionInContainer>
    );
  }

  return (
    <styles.Container>
      <styles.Title>
        Token Vesting
      </styles.Title>
      <styles.UserInfo>
        {vestingDetails.tokensArray.map((el) => (
          <styles.UserInfoRow key={el.title}>
            <p>
              {el.title}
            </p>
            <p>
              {`${el.value} ${rewardTicker}`}
            </p>
          </styles.UserInfoRow>
        ))}
      </styles.UserInfo>
      {
        vestingType === ETypeVesting.Stepwise && (
          <VestingList
            vesting={vestingDetails.array}
            rewardTicker={rewardTicker}
          />
        )
      }
      {
        !vestingEnded && (
          <Timer
            time={timestamp}
            vestingType={vestingType}
            hideBackground
          />
        )
      }
    </styles.Container>
  );
}
