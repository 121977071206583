import { Button } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as BocaChicaLogo } from 'assets/images/bocachica-logo.svg';
import { ReactComponent as WalletLogo } from 'assets/images/icon/wallet.svg';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.white};
  margin: 1.5rem 2rem;
`;

const LogoContainer = styled.div`
  display: flex;
  z-index: 10;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  & > svg {
    margin-right: .5rem;
  }
`;

export default {
  Header,
  LogoContainer,
  BocaChicaLogo,
  StyledButton,
  WalletLogo,
};
