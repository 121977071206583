import { Button } from '@mui/material';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0;
  max-width: 37.5rem;
  width: 100%;
  color: ${({ theme }) => theme.black};
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 2rem;
  margin-bottom: 0.625rem;
  z-index: 5;
  color: ${({ theme }) => theme.white};
`;

const ChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 70px 10px ${({ theme }) => theme.boxShadow};
  padding: 1.25rem;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.white};
  & > div {
    margin: 0.625rem 0;
  }
`;

const PrevLogo = styled.img`
  margin-left: 2rem;
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.blackOpacity015};
  align-self: center;
`;

const ErrorBlock = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.error};
`;

const Description = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.black};
  margin: 0.625rem 0;
`;

const TitleMetadata = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    margin-left: .25rem;
    border-radius: 12px;
    height: 19px;
    width: 19px;
  }
`;

export default {
  Wrapper,
  Title,
  ChildWrapper,
  PrevLogo,
  ErrorBlock,
  Button,
  Description,
  TitleMetadata,
};
