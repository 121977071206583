import styles from './styles';

interface ITextInput {
  name: string;
  label: string;
  type?: string;
  value?: string;
  placeholder: string;
  min?: string;
  error?: boolean;
  handleChange: any;
  disabled?: boolean;
}

export default function TextInput({ handleChange, ...props }: ITextInput) {
  return (
    <styles.TextInput
      {...props}
      autoComplete="off"
      onChange={handleChange}
      onWheel={(e: any) => e.target!.blur()}
    />
  );
}
