import DateAdapter from '@mui/lab/AdapterDayjs';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useState } from 'react';

import { createFormattedDate } from 'services/helpers';
import { IPeriod } from 'shared/interfaces';

interface IDistributeDateSection {
  setPeriods: (periods: IPeriod[]) => void
}

export default function DistributeDateSection({ setPeriods }: IDistributeDateSection) {
  const [date, setDate] = useState<string>('');
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DateTimePicker
        renderInput={(props: TextFieldProps) => <TextField {...props} error={false} />}
        label="Distribute Date (UTC)"
        value={date}
        onChange={(newValue:string | null) => {
          const period: IPeriod = {
            steps: '1',
            percent: '100',
            endPeriod: createFormattedDate(newValue),
          };
          setPeriods([period]);
          setDate(createFormattedDate(newValue));
        }}
        ampm={false}
      />
    </LocalizationProvider>
  );
}
