import { useNavigate } from 'react-router-dom';

import { useData } from 'providers/DataProvider';
import { getToken } from 'providers/helpers';
import { ISale } from 'providers/interfaces';
import { toSale } from 'routes/constant';
import { calcTotalClaimAvailable } from 'shared/calculation';
import useStatus from 'shared/hooks/useStatus';
import { EStatus } from 'shared/interfaces';
import { formatTokenAmount } from 'shared/utils';

import SaleLayoutInfo from '../SaleLayoutInfo';
import HelperSection from './HelperSection';
import styles from './styles';

interface ICard {
  sale: ISale,
}

export default function Card({ sale }: ICard) {
  const navigate = useNavigate();
  const { tokens } = useData();
  const { status } = useStatus(
    sale.startDate,
    sale.endDate,
    sale.totalAmount,
    sale.collectedAmount,
    sale.saleType,
    sale.status,
  );

  const token = getToken(sale.depositTokeId, tokens);
  if (!token?.metadata) return null;
  const depositTokenPrice = formatTokenAmount(sale.price, token.metadata.decimals);
  const formatTotalAmount = formatTokenAmount(sale.totalAmount, token.metadata.decimals);

  return (
    <styles.Container
      onClick={() => navigate(toSale(sale.id))}
      isOpen={status === EStatus.Open}
    >
      <SaleLayoutInfo
        name={sale.metadata.name}
        tokenIcon={token.metadata.icon}
        currency={token.metadata.symbol}
        saleType={sale.saleType}
        status={status}
      />
      <styles.Footer>
        <styles.SaleInfoWrapper>
          <styles.SaleInfoBlock>
            <p>
              {`${sale.metadata.rewardTicker} Sale Price`}
            </p>
            <p>{`${depositTokenPrice} ${token.metadata.symbol}`}</p>

          </styles.SaleInfoBlock>
          <styles.SaleInfoBlock>
            <p>Target</p>
            <p>{`${formatTotalAmount} ${token.metadata.symbol}`}</p>
          </styles.SaleInfoBlock>
        </styles.SaleInfoWrapper>
        <styles.RightSide>
          <HelperSection
            sale={sale}
            status={status}
            totalClaimAvailable={calcTotalClaimAvailable(sale.collectedAmount, sale.price, token.metadata.decimals)}
          />
          <styles.ArrowLogoContainer>
            <styles.ArrowIcon />
          </styles.ArrowLogoContainer>
        </styles.RightSide>
      </styles.Footer>
    </styles.Container>
  );
}
