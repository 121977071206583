import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import { DataProvider } from 'providers/DataProvider';
import { ServiceProvider } from 'providers/ServiceProvider';
import { WalletSelectorProvider } from 'providers/WalletProvider';
import AppRoutes from 'routes';
import theme from 'shared/theme';

import '@near-wallet-selector/modal-ui/styles.css';
import './index.css';

dayjs.extend(utc);

function AppWrapper() {
  return (
    <ThemeProvider theme={theme}>
      <WalletSelectorProvider>
        <ServiceProvider>
          <DataProvider>
            <AppRoutes />
          </DataProvider>
        </ServiceProvider>
      </WalletSelectorProvider>
    </ThemeProvider>
  );
}

createRoot(
  document.getElementById('root')!,
).render(<AppWrapper />);
