import styled from 'styled-components';

const LayoutParent = styled.div`
  position: relative;
  height: 3rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    overflow: hidden;
  `}
`;

const ProjectLayoutImg = styled.img`
  width: 100%;
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  object-fit: cover;
  border-radius: .25rem;
`;

const ProjectInfoNaming = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

const ProjectName = styled.p`
  font-weight: 600;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.black};
`;

const StageRow = styled.div`
  margin-top: .25rem;
  display: flex;
  align-items: center;
  span {
    :nth-of-type(2) {
      margin: 0 .5rem;
    }
  }
`;

const StageIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .813rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.blackOpacity015};
  align-self: center;
`;

const StageTxt = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
  font-size: .75rem;
`;

const StatusRow = styled.div`
  display: flex;
`;

export default {
  LayoutParent,
  ProjectLayoutImg,
  ProjectInfoNaming,
  ProjectName,
  StageRow,
  StageIcon,
  StageTxt,
  StatusRow,
};
