import Big from 'big.js';

import { MICRO_SECOND, DECIMAL_PREFIX_KILO } from 'shared/constants';

const MILI_IN_NANO = 6;
const BASE = 10;
Big.RM = Big.roundDown;
Big.DP = 30;

export const formatTokenAmount = (value: string, decimals: number, precision?: number): string => {
  try {
    return value
    && Big(value).div(Big(BASE).pow(decimals)).toFixed(precision && precision);
  } catch (e){
    return '0';
  }
};

export const parseTokenAmount = (value: string, decimals: number): string => {
  try {
    return value
  && Big(value).times(Big(BASE).pow(decimals || 0)).toFixed(0);
  } catch (e){
    return '0';
  }
};

export const nanosecondsToMilliseconds = (nanoseconds: string | number): number => new Big(nanoseconds)
  .div(new Big(BASE).pow(MILI_IN_NANO)).toNumber();

export const millisecondsToNanoseconds = (milliseconds: number) => milliseconds * MICRO_SECOND;

export const systemSIToPrefixKilo = (quota: number) => quota * DECIMAL_PREFIX_KILO;
export const prefixKiloToSI = (quota: number) => quota / DECIMAL_PREFIX_KILO;
