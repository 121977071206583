import Big from 'big.js';
import { formatNearAmount } from 'near-api-js/lib/utils/format';

import nearIcon from 'assets/images/icon/near-icon.svg';
import { usn, wNearAddress } from 'services/config';
import {
  Action, FungibleTokenContractInterface, IStorageBalance, IStorageBalanceBounds, ITokenMetadata,
} from 'services/interfaces';
import { IRPCProviderService } from 'services/RPCProviderService';
import {
  NEAR_DECIMALS,
  NEAR_TOKEN_ID, STORAGE_TO_REGISTER_FT, STORAGE_TO_REGISTER_WNEAR, ZERO_STR,
} from 'shared/constants';

import { FTChangeMethods, FTViewMethods } from './contractMethods';

const NEAR_TOKEN = {
  decimals: NEAR_DECIMALS,
  icon: nearIcon,
  name: 'Near token',
  version: '0',
  symbol: 'NEAR',
  reference: '',
};

export default class FungibleTokenContract {
  private provider: IRPCProviderService;

  constructor(props: FungibleTokenContractInterface) {
    this.provider = props.provider;
    this.contractId = props.contractId;
    this.metadata = null;
  }

  contractId;

  metadata: ITokenMetadata | null;

  async getMetadata(): Promise<ITokenMetadata | null> {
    try {
      if (this.contractId === wNearAddress) {
        this.metadata = { ...NEAR_TOKEN };
        return NEAR_TOKEN;
      }
      const metadata = await this.provider.viewFunction(FTViewMethods.ftMetadata, this.contractId);
      if (!metadata) return null;

      this.metadata = { ...metadata };
      return metadata;
    } catch (e) {
      console.warn(`Error while loading ${this.contractId}`);
    }
    return null;
  }

  async getStorageBalanceBounce(): Promise<IStorageBalanceBounds | undefined> {
    return this.provider
      .viewFunction(FTViewMethods.storageBalanceBounds, this.contractId);
  }

  async getStorageBalance({ accountId } : { accountId: string }): Promise<IStorageBalance | undefined> {
    return this.provider
      .viewFunction(FTViewMethods.storageBalanceOf, this.contractId, { account_id: accountId });
  }

  async checkStorageBalance({ accountId }: { accountId: string }): Promise<Action | undefined> {
    try {
      if (this.contractId === NEAR_TOKEN_ID || this.contractId === usn) return undefined;
      const storageBalance = await this.getStorageBalance({ accountId });
      const storageBalanceBounds = await this.getStorageBalanceBounce();
      if (!storageBalance || Big(storageBalance.total).lt(storageBalanceBounds?.min || ZERO_STR)) {
        const defaultStorageAmount = this.contractId === wNearAddress
          ? STORAGE_TO_REGISTER_WNEAR
          : STORAGE_TO_REGISTER_FT;

        let storageAmount = defaultStorageAmount;
        if (storageBalanceBounds && Big(storageBalanceBounds.min).gt(storageBalance?.total || ZERO_STR)) {
          const newStorageAmount = Big(storageBalanceBounds.min).minus(storageBalance?.total || ZERO_STR).toFixed();
          const formattedAmount = formatNearAmount(newStorageAmount);
          storageAmount = formattedAmount;
        }

        return {
          receiverId: this.contractId,
          functionCalls: [{
            methodName: FTChangeMethods.storageDeposit,
            args: {
              registration_only: true,
              account_id: accountId,
            },
            amount: storageAmount,
          }],
        };
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  }
}
