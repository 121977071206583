import { displayAmount } from './displayAmount';
import { formatTokenAmount } from './formatAmount';

interface IProps {
  rewardTicker: string;
  minBuy: string;
  maxBuy: string;
  claimBegun: boolean;
  price: string;
  totalAmount: string;
  depositSymbol: string;
  numAccountSales: number;
}

const getParticipantData = (props: IProps, decimals?: number) => {
  const {
    rewardTicker,
    minBuy,
    maxBuy,
    claimBegun,
    price,
    totalAmount,
    depositSymbol,
    numAccountSales,
  } = props;

  const minAllocation = displayAmount(minBuy, decimals);
  const maxAllocation = displayAmount(maxBuy, decimals);

  const depositTokenPrice = decimals ? formatTokenAmount(price, decimals) : price;
  const formattedTotalAmount = displayAmount(totalAmount, decimals);
  return [
    {
      title: 'Num account sales',
      value: numAccountSales,
    },
    {
      title: 'Min Allocation',
      value: `${minAllocation} ${depositSymbol}`,
    },
    {
      title: 'Max Allocation',
      value: `${maxAllocation} ${depositSymbol}`,
    },
    {
      title: 'Claim Begun',
      value: claimBegun ? 'true' : 'false',
    },
    {
      title: `${rewardTicker} Sale Price`,
      value: `${depositTokenPrice} ${depositSymbol}`,
    },
    {
      title: 'Target',
      value: `${formattedTotalAmount} ${depositSymbol}`,
    },
  ];
};

export default getParticipantData;
