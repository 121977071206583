import { ESaleType, ITokenMetadata } from 'services/interfaces';
import { ZERO_STR } from 'shared/constants';
import {
  ERoundType, ESocial, ETypeVesting, IFormattedValues, IValues, IVesting,
} from 'shared/interfaces';

import { getStatus } from './calculationDate';
import { millisecondsToNanoseconds, parseTokenAmount } from './formatAmount';
import getTimeByDate from './getTimeByDate';
import {
  formatVesting, getVestingArray, getVestingType, isNullOrEmpty,
} from './index';

export const formatValues = (
  values: IValues,
  depositTokenMetadata: ITokenMetadata,
  distributeTokenMetadata: ITokenMetadata,
): IFormattedValues => {
  const {
    projectName,
    projectSite,
    description,
    distributeTokenId,
    maxAmount,
    projectSymbol,
    depositTokenId,
    minAllocation,
    maxAllocation,
    limitPerTransaction,
    roundType,
    price,
    roundStartDate,
    roundFinishDate,
    socialTg,
    socialTwitter,
    socialMedium,
    vesting,
  } = values;
  const { symbol, decimals } = depositTokenMetadata;
  const parsedMaxAmount = parseTokenAmount(maxAmount, decimals);
  const parsedMinAllocation = parseTokenAmount(minAllocation, decimals);
  const parsedMaxAllocation = parseTokenAmount(maxAllocation, decimals);
  const parsedLimitPerTransaction = parseTokenAmount(limitPerTransaction, decimals);
  const parsedPrice = parseTokenAmount(price, decimals);

  const roundStartDateValue = getTimeByDate(roundStartDate);
  const roundFinishDateValue = getTimeByDate(roundFinishDate);

  const formattedValues: IFormattedValues = {
    projectName,
    projectSite,
    projectSymbol,
    distributeTokenDecimals: distributeTokenMetadata.decimals,
    distributeTokenId,
    description,
    depositTokenId,
    depositTokenSymbol: symbol,
    maxAmount: parsedMaxAmount,
    minBuy: parsedMinAllocation,
    maxBuy: parsedMaxAllocation,
    limitPerTransaction: parsedLimitPerTransaction,
    roundType,
    price: parsedPrice,
    startAt: millisecondsToNanoseconds(roundStartDateValue).toString(),
    endAt: millisecondsToNanoseconds(roundFinishDateValue).toString(),
    socialTg,
    socialTwitter,
    socialMedium,

    claimAvailable: false,
    refundAvailable: false,

    minNearDeposit: '0',
    stakingContracts: [],
    vesting,
  };

  return formattedValues;
};

export const formatValuesToSale = (values: IValues) => {
  const {
    projectName,
    projectSite,
    description,
    projectSymbol,
    roundType,
    roundStartDate,
    roundFinishDate,
    socialTg,
    socialTwitter,
    socialMedium,
    maxAmount,
    minAllocation,
    maxAllocation,
    price,
    vesting,
    depositToken,
  } = values;

  const roundStartDateValue = isNullOrEmpty(roundStartDate) ? 0 : getTimeByDate(roundStartDate);
  const roundFinishDateValue = isNullOrEmpty(roundFinishDate) ? 0 : getTimeByDate(roundFinishDate);

  const minBuy = isNullOrEmpty(minAllocation) ? ZERO_STR : minAllocation.toString();
  const maxBuy = isNullOrEmpty(maxAllocation) ? ZERO_STR : maxAllocation.toString();
  const formattedPrice = isNullOrEmpty(price) ? ZERO_STR : price.toString();
  const totalAmount = isNullOrEmpty(maxAmount) ? ZERO_STR : maxAmount.toString();
  const collectedAmount = ZERO_STR;
  const saleType = roundType === ERoundType.ByAmount
    ? ESaleType.ByAmount
    : ESaleType.BySubscription;

  const status = getStatus(
    roundStartDateValue,
    roundFinishDateValue,
    totalAmount,
    collectedAmount,
    saleType,
  );

  let vestingArray: IVesting[] = [];
  let vestingType: ETypeVesting | null = null;
  if (vesting && vesting.length) {
    const formattedVesting = formatVesting(vesting);
    vestingType = getVestingType(formattedVesting);
    vestingArray = getVestingArray(formattedVesting, vestingType, roundFinishDateValue) || [];
  }
  const sale = {
    saleType,
    status,
    minBuy,
    maxBuy,
    price: formattedPrice,
    totalAmount,
    numAccountSales: 0,
    collectedAmount,
    endDate: roundFinishDateValue,
    startDate: roundStartDateValue,
    vesting: vestingArray,
    vestingType,
    metadata: {
      name: projectName,
      rewardTicker: projectSymbol,
      description,
      projectUrl: projectSite,
      socials: [
        { value: socialTg, type: ESocial.Telegram },
        { value: socialTwitter, type: ESocial.Medium },
        { value: socialMedium, type: ESocial.Twitter },
      ],
    },
  };
  const tokenMetadata = depositToken?.metadata
    ? {
      icon: depositToken.metadata.icon,
      symbol: depositToken.metadata.symbol,
    }
    : {
      icon: '',
      symbol: '',
    };
  return {
    sale,
    metadata: tokenMetadata,
  };
};
