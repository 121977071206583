import { useMemo } from 'react';

import { calcProgress } from 'shared/calculation';
import { displayAmount } from 'shared/utils';

import styles from './styles';

interface IAmountProgress {
  totalAmount: string;
  collectedAmount: string;
  currency: string;
  depositTokenDecimals?: number;
}

export default function AmountProgress({
  totalAmount,
  collectedAmount,
  currency,
  depositTokenDecimals,
}: IAmountProgress) {
  const formattedTotalAmount = displayAmount(totalAmount, depositTokenDecimals);
  const formattedCollectedAmount = displayAmount(collectedAmount, depositTokenDecimals);
  const progress = useMemo(() => calcProgress(collectedAmount, totalAmount), [collectedAmount, totalAmount]);
  const progressValue = `${formattedCollectedAmount} / ${formattedTotalAmount} ${currency}`;
  return (
    <styles.AmountBlock>
      <styles.Title>
        Progress
      </styles.Title>
      <progress value={formattedCollectedAmount} max={formattedTotalAmount} />
      <styles.Label>
        <span>
          {`${progress}%`}
        </span>
        <span>
          {progressValue}
        </span>
      </styles.Label>
    </styles.AmountBlock>
  );
}
