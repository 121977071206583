import QuestionMark from 'assets/images/icon/question-mark-logo.svg';
import ProjectLogo from 'assets/images/project-placeholder.svg';
import { ESaleType } from 'services/interfaces';
import { EStatus } from 'shared/interfaces';

import Status from '../Status';
import styles from './styles';

interface ISaleLayoutInfo{
  name: string;
  tokenIcon: string;
  currency: string;
  saleType: ESaleType;
  status: EStatus;
}

export default function SaleLayoutInfo({
  name,
  tokenIcon,
  saleType,
  status,
  currency,
}: ISaleLayoutInfo) {
  const titleTarget = saleType === ESaleType.ByAmount ? 'Amount' : 'Subscription';

  return (
    <>
      <styles.LayoutParent>
        <styles.ProjectLayoutImg src={ProjectLogo} alt="layout" />
      </styles.LayoutParent>
      <styles.ProjectInfoNaming>
        <div>
          <styles.ProjectName>
            {name}
          </styles.ProjectName>
          <styles.StageRow>
            <styles.StageIcon src={tokenIcon.length ? tokenIcon : QuestionMark} />
            <styles.StageTxt>
              <span>{currency}</span>
              <span>•</span>
              <span>{titleTarget}</span>
            </styles.StageTxt>
          </styles.StageRow>
        </div>
        <styles.StatusRow>
          <Status type={status} />
        </styles.StatusRow>
      </styles.ProjectInfoNaming>
    </>
  );
}
