import { useMemo } from 'react';

import { useData } from 'providers/DataProvider';
import { projectInfo } from 'shared/constants';
import { EFromValues, IValues } from 'shared/interfaces';

import { TextAreaInput, TextInput } from '../Input/FormikInput';
import TokenBlock from '../TokenBlock';
import styles from './styles';

interface IGeneralInformation {
  values: IValues,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
}

export default function GeneralInformation({
  values,
  setFieldValue,
}: IGeneralInformation) {
  const { tokens } = useData();

  const tokensList = useMemo(() => Object.values(tokens).map((token) => ({
    title: token.metadata ? token.metadata.symbol : token.contractId,
    value: token.contractId,
  })), [tokens]);

  return (
    <styles.Wrapper>
      <styles.Title>General Information</styles.Title>
      <styles.ChildWrapper>
        {projectInfo.map(({
          label, type, name, placeholder,
        }) => (
          <TextInput
            key={name}
            label={label}
            name={name}
            type={type}
            placeholder={placeholder}
          />
        ))}
        <TextAreaInput
          name={EFromValues.description}
          placeholder="3-4 sentences max"
        />
        <TokenBlock
          value={values.distributeTokenId}
          label="Distribute token contract id"
          valueName={EFromValues.distributeTokenId}
          tokenName={EFromValues.distributeToken}
          placeholder="wrap.near"
          optionList={tokensList}
          setFieldValue={setFieldValue}
          token={values.distributeToken}
        />
        <TokenBlock
          value={values.depositTokenId}
          label="Deposit token contract id"
          valueName={EFromValues.depositTokenId}
          tokenName={EFromValues.depositToken}
          placeholder="wrap.near"
          optionList={tokensList}
          setFieldValue={setFieldValue}
          token={values.depositToken}
        />
        <styles.Description>
          <span><strong>Note:</strong></span>
          To create sale you have to upload the image and upload the token metadata
        </styles.Description>
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
