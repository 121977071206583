import {
  createContext, useContext, useState, useEffect, useMemo,
} from 'react';

import { FungibleTokenContract } from 'services/contract';

import { retrieveInitialData } from './helpers';
import { DataContextType, ISale } from './interfaces';
import { useService } from './ServiceProvider';
import { useWalletData } from './WalletProvider';

export const initialDataState: DataContextType = {
  loading: false,
  sales: {},
  tokens: {},
};

const DataContextHOC = createContext<DataContextType>(initialDataState);

export function DataProvider({ children }:{ children: JSX.Element }) {
  const { RPCProvider } = useWalletData();
  const { saleContract } = useService();

  const [loading, setLoading] = useState<boolean>(initialDataState.loading);
  const [sales, setSales] = useState<{ [key:string]: ISale }>(initialDataState.sales);
  const [tokens, setTokens] = useState<{ [key:string]: FungibleTokenContract }>(initialDataState.tokens);

  useEffect(() => {
    const initialLoading = async () => {
      try {
        if (!saleContract) return;
        setLoading(true);
        const {
          salesMap,
          tokenMetadataMap,
        } = await retrieveInitialData(RPCProvider, saleContract);

        setSales(salesMap);
        setTokens(tokenMetadataMap);
      } catch (e) {
        console.warn(`Error: ${e} while initial loading`);
      } finally {
        setLoading(false);
      }
    };
    initialLoading();
  }, [saleContract, RPCProvider]);

  const data = useMemo(() => ({ loading, sales, tokens }), [loading, sales, tokens]);

  return (
    <DataContextHOC.Provider value={data}>
      {children}
    </DataContextHOC.Provider>
  );
}

export const useData = () => useContext(DataContextHOC);
