import { EStatus } from 'shared/interfaces';
import { StatusLocales } from 'shared/utils';

import styles from './styles';

export default function Status({ type }: { type: EStatus }): JSX.Element {
  return (
    <styles.StatusWrapper type={type}>
      <styles.StatusText>
        {StatusLocales[type]}
      </styles.StatusText>
    </styles.StatusWrapper>
  );
}
