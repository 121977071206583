import { FungibleTokenContract } from 'services/contract';
import { VestingOutput } from 'services/interfaces';

export enum ERoundType {
  ByAmount = 'ByAmount',
  BySubscription = 'BySubscription',
  Empty = '',
}

export interface IValues {
  projectName: string;
  projectSite: string;
  projectSymbol: string;
  description: string;
  distributeTokenId: string;
  distributeToken: FungibleTokenContract | null;
  depositTokenId: string;
  depositToken: FungibleTokenContract | null;

  maxAmount: string;

  minAllocation: string;
  maxAllocation: string;
  limitPerTransaction: string;
  roundType: ERoundType;
  price: string;

  roundStartDate: string;
  roundFinishDate: string;

  socialTg: string;
  socialTwitter: string;
  socialMedium: string;

  vestingType: ETypeVesting,
  vesting?: VestingOutput[],
}

export enum EFromValues {
  projectName = 'projectName',
  projectSite = 'projectSite',
  projectSymbol = 'projectSymbol',
  distributeToken = 'distributeToken',
  distributeTokenId = 'distributeTokenId',
  distributeTokenDecimals = 'distributeTokenDecimals',
  description = 'description',
  depositToken = 'depositToken',
  depositTokenId = 'depositTokenId',

  maxAmount = 'maxAmount',
  minAllocation = 'minAllocation',
  maxAllocation = 'maxAllocation',
  limitPerTransaction = 'limitPerTransaction',
  roundType = 'roundType',
  price = 'price',

  roundStartDate = 'roundStartDate',
  roundFinishDate = 'roundFinishDate',

  socialTg = 'socialTg',
  socialTwitter = 'socialTwitter',
  socialMedium = 'socialMedium',

  vestingType = 'vestingType',
  vesting = 'vesting',
}

export interface IProjectInfo {
  label: string;
  type: string;
  name: string;
  placeholder: string;
}

export interface ISocialNetwork {
  label: string;
  type: string;
  name: string;
  placeholder: string;
}

export interface ITextInput {
  key?: string;
  name: string;
  label: string;
  type?: string;
  value?: string;
  placeholder: string;
  optionList?: { title: string; value: string; }[];
}

export interface ISelect {
  name: string;
  children: JSX.Element[];
}

export interface ITextAreaInput {
  name: string;
  placeholder: string;
}

export enum EStatus {
  Open = 'Open',
  Soon = 'Soon',
  Closed = 'Closed',
}

export interface IFormattedValues {
  projectName: string;
  projectSite: string;
  projectSymbol: string;
  distributeTokenDecimals: number;
  distributeTokenId: string;
  description: string;
  depositTokenId: string;
  depositTokenSymbol: string;

  maxAmount: string;
  minBuy: string;
  maxBuy: string;
  limitPerTransaction: string;
  roundType: ERoundType;
  price: string;

  startAt: string;
  endAt: string;

  socialTg?: string;
  socialTwitter?: string;
  socialMedium?: string;

  claimAvailable: boolean,
  refundAvailable: boolean,
  minNearDeposit: string,
  stakingContracts: string[],
  vesting?: VestingOutput[],
}

export interface ITimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export interface IGetTimeAndStatus {
  calcTime: ITimeLeft,
  calcStatus: EStatus,
}

export interface IAdminValues {
  claimAvailable: boolean,
  refundAvailable: boolean,
  newPrice: string,
  newMaxAmount: string,
  distributeTokenId: string;
  newStartDate: string,
  newEndDate: string,

  vestingType: ETypeVesting,
  vesting: VestingOutput[],
}

export enum ESaleMethod {
  REMOVE_SALE,
  UPDATE_SALE_DATES,
  UPDATE_SALE_DISTRIBUTE_TOKEN_ID,
  UPDATE_SALE_PRICE,
  UPDATE_SALE_CLAIM_AND_REFUND,
  DOWNLOAD_SALE_ACCOUNTS,
  CHANGE_VESTING,
}

export enum ETypeVesting {
  OneTime = 'OneTime',
  Stepwise = 'Stepwise',
}

export interface IVesting {
  dateObj: IParseDate;
  steps: number;
  quota: number;
  date: number;
  previousVestingDate?: number,
}

export interface IPeriod {
  percent: string,
  startPeriod?: string,
  endPeriod: string,
  steps: string,
}

export enum EPeriodsErrors {
  invalidDistributionDate = 'invalidDistributionDate',
  invalidPercentAmount = 'invalidPercentAmount',
  invalidPeriods = 'invalidPeriods',
}

export const ErrorsPeriodsMap: { [key: string]: string } = {
  [EPeriodsErrors.invalidDistributionDate]: 'Invalid Date.',
  [EPeriodsErrors.invalidPercentAmount]: 'The sum of all percentages must be equal to 100',
  [EPeriodsErrors.invalidPeriods]: 'Invalid Periods',
};

export interface IParseDate {
  year: number;
  month: string;
  day: string;
  hours: string;
  minutes: string;
}

export enum ESocial {
  Medium,
  Telegram,
  Twitter,
}

export interface ISocials {
  value: string | null;
  type: ESocial;
}

export enum EVestingStatus {
  SOON = 'SOON',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  AVAILABLE_TO_CLAIM = 'AVAILABLE_TO_CLAIM',
}

export interface IVestingArray extends IVesting {
  amount: string;
  status: EVestingStatus;
}

export interface IVestingDetails {
  totalLocked: string;
  availableTokens: string;
  array: IVestingArray[];
  tokensArray: {
    title: string;
    value: string;
  }[];
}
