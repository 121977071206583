export const FTViewMethods = {
  ftMetadata: 'ft_metadata',
  storageBalanceOf: 'storage_balance_of',
  storageBalanceBounds: 'storage_balance_bounds',
};

export const FTChangeMethods = {
  storageDeposit: 'storage_deposit',
};

export const SaleViewMethods = {
  getNumSales: 'get_num_sales', // (&self)
  getSales: 'get_sales', // (&self, from_index: u64, limit: u64)
  getSaleAccounts: 'get_sale_accounts', // (&self, sale_id: u64, from_index: u64, limit: u64,)
};

export const SaleChangeMethods = {
  createSale: 'create_sale', // (&mut self, sale: SaleInput)
  removeSale: 'remove_sale', // (&mut self, sale_id: u64)
  updateSaleDates: 'update_sale_dates', // (&mut self, sale_id: u64, start_date: U64, end_date: U64)
  updateSaleDistributeTokenId: 'update_sale_distribute_token_id',
  // ( &mut self, sale_id: u64, distribute_token_id: AccountId)

  updateSalePrice: 'update_sale_price', // (&mut self, sale_id: u64, price: U128, max_amount: U128)

  updateSaleClaimAvailable: 'update_sale_claim_available', // (&mut self, sale_id: u64, claim_available: bool)
  updateSaleRefundAvailable: 'update_sale_refund_available', // (&mut self, sale_id: u64, refund_available: bool)
  changeVesting: 'change_vesting', // (&mut self, sale_id: u64, mut input_vesting: Vec<VestingPeriod>)
};
