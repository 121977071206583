import styled from 'styled-components';

const DeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1.5rem;
  `}
  span {
    color: ${({ theme }) => theme.grey};
    font-weight: 600;
    font-size: .75rem;
  }
  p {
    color: ${({ theme }) => theme.black};
    font-weight: 600;
    font-size: 1rem;
    padding-top: .375rem;
  }
  div {
    :last-child {
      text-align: right;
    }
  }
`;

export default {
  DeadlineWrapper,
};
