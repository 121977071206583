import { useNavigate } from 'react-router-dom';

import { useWalletData } from 'providers/WalletProvider';
import { APP_ROUTES } from 'routes/constant';

import styles from './styles';

export default function Header(){
  const { openModal, accountId, isSignedIn } = useWalletData();

  const navigate = useNavigate();
  const title = isSignedIn
    ? accountId
    : 'Connect Wallet';

  return (
    <styles.Header>
      <styles.LogoContainer onClick={() => navigate(APP_ROUTES.HOME)}>
        <styles.BocaChicaLogo />
      </styles.LogoContainer>
      <styles.StyledButton
        variant="contained"
        color="warning"
        onClick={openModal}
      >
        <styles.WalletLogo />
        {title}
      </styles.StyledButton>
    </styles.Header>
  );
}
