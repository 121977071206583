import DateAdapter from '@mui/lab/AdapterDayjs';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, FormControlLabel, Switch } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useCallback, useState } from 'react';

import { ISale } from 'providers/interfaces';
import { useService } from 'providers/ServiceProvider';
import { useWalletData } from 'providers/WalletProvider';
import { createFormattedDate } from 'services/helpers';
import { ITokenMetadata, VestingOutput } from 'services/interfaces';
import { ESaleMethod, IAdminValues } from 'shared/interfaces';
import {
  disableFields, EAdminValue, setupInitialAdminValues, formatAdminValue, formatTokenAmount,
} from 'shared/utils';

import TextInput from '../Input/TextInput';
import VestingAdminPanel from '../VestingAdminPanel';
import styles from './styles';

interface IAdminPanel {
  sale: ISale;
  depositTokenMetadata: ITokenMetadata,
}

export default function AdminPanel({ sale, depositTokenMetadata }: IAdminPanel) {
  const { isSignedIn } = useWalletData();
  const {
    removeSale,
    updateSaleDates,
    updateSaleDistributeTokenId,
    updateSalePrice,
    updateSaleClaimAndRefund,
    downloadSaleAccounts,
    changeVesting,
  } = useService();

  const [values, setValues] = useState<IAdminValues>(
    setupInitialAdminValues(sale, depositTokenMetadata.decimals),
  );
  const [isValidVesting, setIsValidVesting] = useState<boolean>(false);

  const { buttons, inputs } = disableFields(
    sale,
    values,
    isSignedIn,
    isValidVesting,
    depositTokenMetadata.decimals,
  );

  const handleChange = (e: { target: { name: string; value: string; }; }) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleClick = useCallback((saleMethod: ESaleMethod) => {
    const newValues = formatAdminValue(values, depositTokenMetadata);
    switch (saleMethod) {
      case ESaleMethod.REMOVE_SALE:
        removeSale(sale.id);
        break;
      case ESaleMethod.UPDATE_SALE_DATES:
        updateSaleDates(sale.id, newValues.newStartDate, newValues.newEndDate);
        break;
      case ESaleMethod.UPDATE_SALE_DISTRIBUTE_TOKEN_ID:
        updateSaleDistributeTokenId(sale.id, newValues.distributeTokenId);
        break;
      case ESaleMethod.UPDATE_SALE_PRICE:
        updateSalePrice(sale.id, newValues.newPrice, newValues.newMaxAmount);
        break;
      case ESaleMethod.UPDATE_SALE_CLAIM_AND_REFUND:
        updateSaleClaimAndRefund(sale.id, newValues.claimAvailable, newValues.refundAvailable, sale);
        break;
      case ESaleMethod.DOWNLOAD_SALE_ACCOUNTS:
        downloadSaleAccounts(sale.id, sale.numAccountSales, depositTokenMetadata);
        break;
      case ESaleMethod.CHANGE_VESTING:
        changeVesting(sale.id, newValues.vesting);
        break;

      default:
        break;
    }
  }, [removeSale, sale, depositTokenMetadata, values]);

  return (
    <styles.Container>
      <p>Admin Panel</p>
      <styles.Row>
        <TextInput
          value={values.newPrice}
          label="Update Price"
          name={EAdminValue.newPrice}
          type="number"
          placeholder="0.15"
          handleChange={handleChange}
          disabled={inputs.inputSalePrice}
        />
        <TextInput
          value={values.newMaxAmount}
          label="Update Target Amount"
          name={EAdminValue.newMaxAmount}
          type="number"
          placeholder="3333"
          handleChange={handleChange}
          disabled={inputs.inputSalePrice}
        />
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="warning"
          disabled={buttons.disableSalePrice}
          onClick={() => handleClick(ESaleMethod.UPDATE_SALE_PRICE)}
        >
          Update Sale Price
        </Button>
      </styles.WrapperButton>
      <styles.Row>
        <TextInput
          value={values.distributeTokenId}
          label="Update Distribute Token Id"
          name={EAdminValue.distributeTokenId}
          type="text"
          placeholder="wrap.near"
          handleChange={handleChange}
          disabled={!isSignedIn}
        />
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="warning"
          disabled={buttons.disableDistributeTokenId}
          onClick={() => handleClick(ESaleMethod.UPDATE_SALE_DISTRIBUTE_TOKEN_ID)}
        >
          Update Distribute Token Id
        </Button>
      </styles.WrapperButton>
      <styles.Row>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DateTimePicker
            renderInput={(props: TextFieldProps) => <TextField {...props} error={false} />}
            label="Start Date (UTC)"
            value={values.newStartDate}
            onChange={(newValue:string | null) => {
              setValues(
                {
                  ...values,
                  [EAdminValue.newStartDate]:
                  createFormattedDate(newValue),
                },
              );
            }}
            ampm={false}
            disabled={inputs.inputSaleDates}
          />
          <DateTimePicker
            renderInput={(props: TextFieldProps) => <TextField {...props} error={false} />}
            label="End Date (UTC)"
            value={values.newEndDate}
            onChange={(newValue: string | null) => {
              setValues(
                {
                  ...values,
                  [EAdminValue.newEndDate]:
                  createFormattedDate(newValue),
                },
              );
            }}
            ampm={false}
            disabled={inputs.inputSaleDates}
          />
        </LocalizationProvider>
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="warning"
          disabled={buttons.disableSaleDates}
          onClick={() => handleClick(ESaleMethod.UPDATE_SALE_DATES)}
        >
          Update Sale Dates
        </Button>
      </styles.WrapperButton>
      <styles.Row>
        <FormControlLabel
          value={values.claimAvailable}
          checked={values.claimAvailable}
          onChange={(e, checked) => setValues({ ...values, [EAdminValue.claimAvailable]: checked })}
          control={<Switch color="warning" />}
          label="Claim"
          labelPlacement="start"
          disabled={!isSignedIn}
        />
        <FormControlLabel
          value={values.refundAvailable}
          checked={values.refundAvailable}
          onChange={(e, checked) => setValues({ ...values, [EAdminValue.refundAvailable]: checked })}
          control={<Switch color="warning" />}
          label="Refund"
          labelPlacement="start"
          disabled={!isSignedIn}
        />
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="warning"
          disabled={buttons.disableClaimAndRefund}
          onClick={() => handleClick(ESaleMethod.UPDATE_SALE_CLAIM_AND_REFUND)}
        >
          Update Claim and Refund
        </Button>
      </styles.WrapperButton>
      <styles.VestingContainer>
        <VestingAdminPanel
          initialVestingType={values.vestingType}
          saleTotalAmount={formatTokenAmount(sale.totalAmount, depositTokenMetadata.decimals)}
          saleEndDate={sale.endDate}
          setIsValidVesting={setIsValidVesting}
          handleChange={(vesting: VestingOutput[]) => setValues({ ...values, [EAdminValue.vesting]: vesting })}
          currentVesting={values.vesting}
        />
        <Button
          variant="contained"
          color="warning"
          onClick={() => handleClick(ESaleMethod.CHANGE_VESTING)}
          disabled={buttons.disableChangeVesting}
        >
          Update Vesting
        </Button>
      </styles.VestingContainer>
      {isSignedIn && sale.numAccountSales > 0 && (
        <Button
          variant="outlined"
          color="info"
          onClick={() => handleClick(ESaleMethod.DOWNLOAD_SALE_ACCOUNTS)}
        >
          Download List Account
        </Button>
      )}
      <Button
        variant="contained"
        color="error"
        disabled={buttons.disableRemoveSale}
        onClick={() => handleClick(ESaleMethod.REMOVE_SALE)}
      >
        Remove Sale
      </Button>
    </styles.Container>
  );
}
