import { Alert } from '@mui/material';

import styles from './styles';

export default function LoadingRocket() {
  return (
    <styles.Container>
      <styles.RocketLogo />
      <Alert variant="filled" severity="error">
        You must be logged in to create a sale
      </Alert>
    </styles.Container>
  );
}
