import { ERoundType, ETypeVesting, IValues } from 'shared/interfaces';

const initialValues: IValues = {
  projectName: '',
  description: '',
  projectSymbol: '',
  distributeTokenId: '',
  distributeToken: null,
  depositTokenId: '',
  depositToken: null,

  maxAmount: '',
  minAllocation: '',
  maxAllocation: '',
  limitPerTransaction: '',
  roundType: ERoundType.Empty,
  price: '',

  roundStartDate: '',
  roundFinishDate: '',

  projectSite: '',
  socialTg: '',
  socialTwitter: '',
  socialMedium: '',

  vestingType: ETypeVesting.OneTime,
  vesting: undefined,
};

export default initialValues;
