import { css, DefaultTheme } from 'styled-components/macro';

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export interface ThemeColors {
  black: string;
  white: string;
  grey: string;
  blue: string;
  orange: string;
  grayOp01: string;
  grayOp04: string;
  grayOp06: string;

  boxShadow: string;
  greyBorderBox: string;
  grayHover: string;

  orange1: string;
  orangeFocused: string;
  grayBg: string;
  progressBar: string;
  progressBarCompleted: string;
  blackText: string;
  greySearch: string;
  placeholder: string;
  error: string;

  statusOpenBg: string,
  statusSoonBg: string,
  statusEndedBg: string,
  statusOpenText: string,
  statusSoonText: string,
  statusEndedText: string,

  lightOrange: string,
  primaryGrey: string,
  orangeDivision: string,

  blackOpacity015: string;
  orangeRGB: string;
  bgGrey: string;
  lightGrey: string;
  darkGrey: string;

  red: string;
  redDot: string;
  shadowWrapper: string;
  boxShadowInput: string;
  opacityGrey: string;
  transparent: string;

  orangeHover: string,
  orangeActive: string,
  lightOrangeHover: string,
  lightOrangeActive: string,

  orangeTextHighlight: string,
  statusOpenCard: string,
}

export const colors: ThemeColors = {
  black: '#131313',
  white: '#FFFFFF',
  grey: '#A1A1A1',
  blue: '#4299e1',
  orange: '#FF5924',
  grayOp01: '#F4F4f5',
  grayOp04: '#D2D4D7',
  grayOp06: '#BBBEC2',

  boxShadow: 'rgba(34, 60, 80, 0.75)',
  greyBorderBox: '#E1E1E1',
  grayHover: '#E1E1E1',

  grayBg: 'rgba(179, 179, 179, 0.2)',
  orangeFocused: 'rgba(255, 122, 0, 0.1)',
  orange1: '#FF7A00',
  progressBar: '#FFC3B0',
  progressBarCompleted: '#FF5924',
  blackText: '#424242',
  greySearch: '#F0F0F0',
  placeholder: '#E1E1E1',
  error: '#FF0505',

  statusOpenBg: '#DCEED1',
  statusSoonBg: '#FDE9CC',
  statusEndedBg: '#E8E9EB',
  statusOpenText: '#51A81B',
  statusSoonText: '#F49302',
  statusEndedText: '#8E939A',

  lightOrange: '#FFEEE9',
  primaryGrey: '#8E939A',
  orangeDivision: '#FFD0C1',

  blackOpacity015: 'rgba(0, 0, 0, 0.15)',
  orangeRGB: '255, 89, 36',
  bgGrey: '181,187,194',
  lightGrey: '#676C73',
  darkGrey: '#B3B3B3',

  red: '#F65151',
  redDot: '#F86069',
  shadowWrapper: 'rgba(19, 19, 19, 0.2)',
  boxShadowInput: 'rgba(255, 89, 36, 0.1)',
  opacityGrey: 'rgba(181, 187, 194, 0.2)',
  transparent: 'transparent',

  orangeHover: '#F54F1A',
  orangeActive: '#EB4510',
  lightOrangeHover: '#FFDED3',
  lightOrangeActive: '#FFCDBD',

  orangeTextHighlight: '#FFCDBD',
  statusOpenCard: '#51A81B',
};

function theme(): DefaultTheme {
  return {
    ...colors,

    // media queries
    mediaWidth: mediaWidthTemplates,

  };
}

export default theme;
