import { LoadingButton } from '@mui/lab';
import { useCallback, useEffect, useState } from 'react';

import { useData } from 'providers/DataProvider';
import { retrieveMetadataToken } from 'providers/helpers';
import { useWalletData } from 'providers/WalletProvider';
import { FungibleTokenContract } from 'services/contract';
import { EFromValues } from 'shared/interfaces';

import { AutocompleteInput } from '../Input/FormikInput';
import styles from './styles';

export default function TokenBlock({
  value,
  label,
  valueName,
  tokenName,
  placeholder,
  optionList,
  setFieldValue,
  token,
}: {
  value: string,
  label: string,
  valueName: EFromValues,
  tokenName: EFromValues,
  placeholder: string,
  optionList: {
    title: string;
    value: string;
  }[],
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  token: FungibleTokenContract | null,
}) {
  const { RPCProvider } = useWalletData();
  const { tokens } = useData();

  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingTokenFailed, setIsLoadingTokenFailed] = useState<boolean>(false);
  const metadata = token?.metadata;

  const handleClick = useCallback(async () => {
    setLoading(true);
    const retrievedToken = await retrieveMetadataToken(
      value,
      RPCProvider,
      tokens,
      setLoading,
      setIsLoadingTokenFailed,
    );
    setFieldValue(tokenName, retrievedToken);
  }, [RPCProvider, tokenName, setFieldValue, tokens, value]);

  useEffect(() => {
    if (token?.metadata && token?.contractId !== value) {
      setFieldValue(tokenName, null);
    }
  }, [tokenName, token?.contractId, token?.metadata, value, setFieldValue]);

  const titleButton = metadata ? (
    <styles.TitleMetadata>
      symbol:
      {' '}
      {metadata.symbol}
      , decimals:
      {' '}
      {metadata.decimals}
      , icon:
      {' '}
      <img src={metadata.icon} alt={metadata.symbol} />
    </styles.TitleMetadata>
  )
    : 'Get Metadata';

  return (
    <>
      <AutocompleteInput
        value={value}
        label={label}
        name={valueName}
        type="text"
        placeholder={placeholder}
        optionList={optionList}
        setFieldValue={setFieldValue}
      />
      <LoadingButton
        onClick={handleClick}
        loading={loading && !token?.metadata}
        variant="contained"
        color={metadata ? 'success' : 'primary'}
      >
        {titleButton}
      </LoadingButton>
      {isLoadingTokenFailed && (
        <styles.ErrorBlock>Error token metadata</styles.ErrorBlock>
      )}
    </>
  );
}
