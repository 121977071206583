import Big from 'big.js';

import { ISale } from 'providers/interfaces';
import { createFormattedDate } from 'services/helpers';
import { ITokenMetadata } from 'services/interfaces';
import { ZERO } from 'shared/constants';
import { ETypeVesting, IAdminValues } from 'shared/interfaces';

import getTimeByDate from './getTimeByDate';
import {
  parseTokenAmount, isNullOrEmpty, millisecondsToNanoseconds, formatTokenAmount,
} from './index';

export const setupInitialAdminValues = (
  sale: ISale,
  decimals: number,
): IAdminValues => ({
  claimAvailable: sale.claimAvailable,
  refundAvailable: sale.refundAvailable,
  newPrice: formatTokenAmount(sale.price, decimals),
  newMaxAmount: formatTokenAmount(sale.totalAmount, decimals),
  distributeTokenId: sale.distributeTokenId || '',
  newStartDate: createFormattedDate(new Date(sale.startDate)),
  newEndDate: createFormattedDate(new Date(sale.endDate)),
  vestingType: ETypeVesting.OneTime,
  vesting: [],
});

export enum EAdminValue {
  claimAvailable = 'claimAvailable',
  refundAvailable = 'refundAvailable',
  newPrice = 'newPrice',
  distributeTokenId = 'distributeTokenId',
  newMaxAmount = 'newMaxAmount',
  newStartDate = 'newStartDate',
  newEndDate = 'newEndDate',
  vestingType = 'vestingType',
  vesting = 'vesting',
}

export const disableFields = (
  sale: ISale,
  value: IAdminValues,
  isSignedIn: boolean,
  isValidVesting: boolean,
  decimals: number,
) => {
  const dateNow = Date.now();

  const isSaleStart = dateNow > sale.startDate;
  const similarDisable = !isSignedIn || isSaleStart;

  const disableSalePrice = similarDisable
    || isNullOrEmpty(value.newPrice)
    || (Big(value.newPrice || ZERO).eq(formatTokenAmount(sale.price, decimals)))
    || isNullOrEmpty(value.newMaxAmount)
    || (Big(value.newMaxAmount || ZERO).eq(formatTokenAmount(sale.totalAmount, decimals)));

  const disableDistributeTokenId = !isSignedIn
    || isNullOrEmpty(value.distributeTokenId)
    || (value.distributeTokenId === sale.distributeTokenId);

  const disableSaleDates = similarDisable
    || isNullOrEmpty(value.newStartDate)
    || (value.newStartDate === createFormattedDate(new Date(sale.startDate)))
    || isNullOrEmpty(value.newEndDate)
    || (value.newEndDate === createFormattedDate(new Date(sale.endDate)));

  const disableRemoveSale = !isSignedIn
    || Big(sale.collectedAmount).gt('0');

  const disableClaimAndRefund = !isSignedIn
  || ((sale.claimAvailable === value.claimAvailable) && (sale.refundAvailable === value.refundAvailable))
  || !sale.distributeTokenId
  || !sale.distributeTokenDecimals;

  const disableChangeVesting = !isSignedIn
  || !isValidVesting;

  const buttons = {
    disableSalePrice,
    disableDistributeTokenId,
    disableSaleDates,
    disableRemoveSale,
    disableClaimAndRefund,
    disableChangeVesting,
  };

  const inputSalePrice = similarDisable;
  const inputSaleDates = similarDisable;

  const inputs = {
    inputSalePrice,
    inputSaleDates,
  };
  return {
    buttons,
    inputs,
  };
};

export const formatAdminValue = (values: IAdminValues, tokenMetadata: ITokenMetadata) => {
  const { decimals } = tokenMetadata;

  const {
    claimAvailable,
    refundAvailable,
    newPrice,
    newMaxAmount,
    distributeTokenId,
    newStartDate,
    newEndDate,
    vesting,
  } = values;

  const newValues = {
    claimAvailable,
    refundAvailable,
    newPrice: parseTokenAmount(newPrice, decimals),
    newMaxAmount: parseTokenAmount(newMaxAmount, decimals),
    distributeTokenId,
    newStartDate: millisecondsToNanoseconds(getTimeByDate(newStartDate)),
    newEndDate: millisecondsToNanoseconds(getTimeByDate(newEndDate)),
    vesting,
  };
  return newValues;
};
