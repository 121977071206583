import { getParticipantData } from 'shared/utils';

import styles from './styles';

interface IValueSale {
  depositSymbol: string;
  depositTokenDecimals?: number;
  rewardTicker: string;
  minBuy: string;
  maxBuy: string;
  claimBegun: boolean;
  price: string;
  totalAmount: string;
  numAccountSales: number;
}

export default function ValueSale(
  {
    depositSymbol,
    depositTokenDecimals,
    rewardTicker,
    minBuy,
    maxBuy,
    claimBegun,
    price,
    totalAmount,
    numAccountSales,
  }: IValueSale,
) {
  const props = {
    rewardTicker,
    minBuy,
    maxBuy,
    claimBegun,
    price,
    totalAmount,
    depositSymbol,
    numAccountSales,
  };
  const participantData = getParticipantData(props, depositTokenDecimals);

  return (
    <styles.Wrapper>
      {
        participantData.map(({
          title, value,
        }) => (
          <styles.Item key={title}>
            <styles.Title>
              {title}
            </styles.Title>
            <styles.Data>
              {value}
            </styles.Data>
          </styles.Item>
        ))
      }
    </styles.Wrapper>
  );
}
